import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import reqHeader from "../../helper/reqHeader"
import Table from "../Table/Table";
import { Alert, CustomDialog, Confirm, Dialog, useDialog } from "react-st-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const PopupCustomerUserDetails = (props) => {

  const {mobile} = props;

  const userId = mobile.slice(2);

  const [userDetails, setUserDetails] = useState([])
  const dialog = useDialog();

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
      // disable right click
      document.addEventListener('contextmenu', event => event.preventDefault());
   
      document.onkeydown = function (e) {
   
          // disable F12 key
          if(e.keyCode == 123) {
              return false;
          }
   
          // disable I key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
              return false;
          }
   
          // disable J key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
              return false;
          }
   
          // disable U key
          if(e.ctrlKey && e.keyCode == 85) {
              return false;
          }
      }
  },[])

  useEffect(()=>{
    async function getUserDetails() {
      const url = `${process.env.REACT_APP_URL}/getuserdetails`;
      const headers = reqHeader(true, true, true);
      const params = {
        mobile: props.mobile && props.mobile
      }
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          setUserDetails(res.data.data)
       })
        .catch((err) => {});
    }
    getUserDetails();

  },[])

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
       
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.silenceRemovedLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "File Duration",
        accessor: "fileDuration",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Assign files",
        accessor: "status",
        sortType: "basic",
        filter: "text",
      },

    ],
    []
  );

    return (
      <>
        <ToastContainer />
         <div className="addNewUserPop" style={{margin:"10px"}}>
          <div>UserID: {userId}</div>
         <Table
            columns={columns}
            data={userDetails}
            count={userDetails.length}
            pagination={true}
            expanded={false}
            isHeader={true}
            filter={true}
            isCountDisplayed={true}
            customText="userDetails"
          />  
           <div><button>Submit</button></div>         
        </div>
      </>
    );

};

export default PopupCustomerUserDetails;