import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Confirm } from 'react-st-modal';
import "react-toastify/dist/ReactToastify.css";

const AdminEndUserList = () =>  {
  const [endUsers, setEndUsers] = useState([]);

  useEffect(()=>{
    async function getEndUserList() {
      const url = `${process.env.REACT_APP_URL}/getenduserlist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)

      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("End User", res.data.data);
          setEndUsers(res.data.data);
        })
        .catch((err) => {});
    }
    getEndUserList();
  },[]);

  const onDisableUser = async(userId, e) => {

    const result = await Confirm(
      "Do you want disable the User? Once Disabled you will not be able to change it."
    );

    if(result){
      const headers = reqHeader(true, true, true);
  
      let body={
        _id:userId
      }
      
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/disableuser`;

      await axios
        .put(url,body,config)
        .then((res) => {
          toast.success(res.data.msg);
          window.location.reload();
        }).catch((err)=>{
          console.log("err", err)
        });
    }
   
  }

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Coordinator Name",
        accessor: "coordinator",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "District",
        accessor: "district",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Password",
        accessor: "accesscode",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Registered On",
        accessor: "createdOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.createdOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Recorded Hours",
        accessor: "recordedHours",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Disable",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <button           
                onClick={(e) => onDisableUser(row.original._id, e)}
                className="btn blueBtn-fill">
                Disable
              </button>

            </div> 
          );
        }
      },

    ],
    []
  );


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <ToastContainer />
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                user Details
              </div>

              <UserProfile />
            </div>
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={endUsers}
                      count={endUsers.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Participant-List"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AdminEndUserList;