import axios from "axios";
import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
  USER_MSG_UPDATE_SUCCESS,
  USER_MSG_UPDATE_FAIL,
  USER_MSG_UPDATE_REQUEST,
} from "../constants/userConstants.js";
import reqHeader from "../helper/reqHeader";

export const login = (formData) => async (dispatch) => {
  try {
    dispatch({ type: USER_LOGIN_REQUEST });

    const { mobile, accesscode } = formData;

    const headers = reqHeader(true, false, true);

    const url = `${process.env.REACT_APP_URL}/login`;

    const body = {
      mobile,
      accesscode,
    };

    console.log("body", body);
    const config = {
      headers,
    };

    const { data } = await axios.post(url, body, config);

    console.log("login data", data);

    dispatch({ type: USER_LOGIN_SUCCESS, payload: data });

    localStorage.setItem("userInfo", JSON.stringify(data));
  } catch (error) {
    //console.log("Login error", error.response.data.error);
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: error.response.data,
      // payload:
      //   error.message && error.response.data.error
      //     ? error.response.data.error
      //     : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("userInfo");
  localStorage.setItem("logout-event", "logout" + Math.random());
  dispatch({ type: USER_LOGOUT });
};

export const clearMessage = (userLogin) => async (dispatch) => {
  try {
    dispatch({ type: USER_MSG_UPDATE_REQUEST });

    const userData = {
      msg: "",
      error: "",
      user: userLogin.user,
      //loginLocation: userLogin.loginLocation,
      token: userLogin.token,
    };

    dispatch({ type: USER_MSG_UPDATE_SUCCESS, payload: userData });

    localStorage.setItem("userInfo", JSON.stringify(userData));
  } catch (error) {
    const { data } = error.response;

    const oldData = {
      user: userLogin.user,
      //loginLocation: userLogin.loginLocation,
      token: userLogin.token,
      ...data,
    };

    dispatch({
      type: USER_MSG_UPDATE_FAIL,
      payload: oldData,
    });
  }
};
