import React, { useEffect, useMemo, useState } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import axios from "axios";
import reqHeader from "../helper/reqHeader"
import Table from "./Table/Table";
import { Alert, CustomDialog, Confirm, Dialog, useDialog } from "react-st-modal";
import {useLocation} from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";


const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
})

const Intra2UserFileList = () => {
  const location = useLocation();
  const {mobile, name, age, gender} = location.state;
  console.log("location.state", location.state)
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });


  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [imgSrc, setImgSrc] = useState([]);
  const [intraonespeaker, setIntraOneSpeaker] = useState();
  const dialog = useDialog();

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
      // disable right click
      document.addEventListener('contextmenu', event => event.preventDefault());
   
      document.onkeydown = function (e) {
   
          // disable F12 key
          if(e.keyCode == 123) {
              return false;
          }
   
          // disable I key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
              return false;
          }
   
          // disable J key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
              return false;
          }
   
          // disable U key
          if(e.ctrlKey && e.keyCode == 85) {
              return false;
          }
      }
  },[])

  useEffect(()=>{
    async function getUserDetails() {
      const url = `${process.env.REACT_APP_URL}/getuserdetails`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        mobile
      }
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("res.data.data", res.data.data)
          //setUserDetails(res.data.data);

          // const boolsorted = res.data.data.sort((a,b) => a.isQcAccepted - b.isQcAccepted);
          // let sorted=boolsorted.sort((a, b) => a.status.localeCompare(b.status));
          //setFileList(sorted);
          const times = [];
          const listOfFiles = [];
          res.data.data.map((filedata)=>{
            if(filedata.intra1CheckStatus == "Accepted"){
              listOfFiles.push(filedata)
              times.push(filedata.fileDuration)
            }         
            })
            setFileList(listOfFiles)
            const sum = sumTime(times)
            setTotalDuration(sum)
       })
        .catch((err) => {});
    }
    getUserDetails();

  },[])

  const sumTime = (times) => {
    console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

  const imageclick = (imgpath) => {
    console.log("imgpath", imgpath)
    setImgSrc(imgpath)
  }
  
  const onAcceptFile = async(fid,e) => {
      const headers = reqHeader(true, true, true);
      let body={
        id:fid,
        role: "Intra2",
        intra2CheckStatus: "Accepted"
      }
      console.log("body", body)
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/intraacceptrejectfile`;
      await axios
        .put(url,body,config)
        .then((res) => {
          console.log("res", res)
          toast.success(res.data.msg);
          let acbtn= document.getElementById(e.target.id);
          acbtn.disabled=true;
          let rejectbtn=document.getElementById(`rejbtn${fid}`);
          rejectbtn.disabled=true;
          // window.location.reload();
        });
  };

  const onHoldFile = async(fid, e) => {

      const headers = reqHeader(true, true, true);
       
        let body={
          id:fid,
          role: "Intra2",
          intra2CheckStatus: "Hold"
        }

        console.log("body", body)
        const config = { headers };
        let url = `${process.env.REACT_APP_URL}/intraacceptrejectfile`;
  
        await axios
          .put(url,body,config)
          .then((res) => {
            
            let acbtn= document.getElementById(`acpbtn${fid}`);
            acbtn.disabled=true;
            let rejectbtn=document.getElementById(e.target.id);
            console.log("rejectbtn", rejectbtn)
            rejectbtn.disabled=true;
            // window.location.reload();
          });
  }
        


  let columns = useMemo(
    () => [
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div onClick={(e) =>imageclick(row.original.imageLocation)}>
              {row.original.imageName}
            </div>
          );
        }
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Speaker",
        accessor: "intra1Speaker",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Accept",
        accessor: "",
        sortType: "basic",
        filter: "text",
        // fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            row.original.intra2CheckStatus == "Accepted" || row.original.intra2CheckStatus == "Hold" ? "" :
            <div>
              <button id={`acpbtn${row.original._id}`}              
                onClick={(e) => onAcceptFile(row.original._id, e)}
                className="btn blueBtn-fill">
                Accept
              </button>

            </div>
          );
        }
      },
      {
        Header: "Hold",
        accessor: "",
        sortType: "basic",
        filter: "text",
        // fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            row.original.intra2CheckStatus == "Hold" || row.original.intra2CheckStatus == "Accepted" ? "" :
            <div>
              <button id={`rejbtn${row.original._id}`}            
                onClick={(e) => onHoldFile(row.original._id, e)}
                className="btn blueBtn-fill">
                Hold
              </button>

            </div>
          );
        }
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.intra2CheckStatus == "Accepted" ? "Accepted" : row.original.intra2CheckStatus == "Hold" ? "Hold" : row.original.intra1CheckStatus == "Accepted" ? "Pending" : ""}
            </div>
          );
        },
      },
    ],
    []
  );


    return (
      <>
       
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Intra2 Check
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between">
                <div className="adminDetails">
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>

              </div>
            </div>
            <div className="hover_img" style={{textAlign:"end"}}>
            <a href="#"><span><img src={imgSrc} alt="" style={{width:"200px", height:"200px"}}/></span></a>
            </div>
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );

};

export default Intra2UserFileList;