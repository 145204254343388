import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import PopupAddUser from "./Pop-ups/admin-add-user";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import Select from "react-select";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import readXlsxFile from 'read-excel-file';
import Spinner from "./spinner";

const schema = yup.object().shape({
  // state: yup
  //   .string()
  //   .required("State is required!"),
  // district: yup
  //   .string()
  //   .required("district is required!"),
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const QcInterPairCheck = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [imgSrc, setImgSrc] = useState([]);
  const [reasonforrejection, setReasonForRejection] = useState();
  //const [resultData, setResultData] = useState([]);
  const [rowData, setRowData] = useState([]);
  let [Counter, setCounter] = useState(0)

  useEffect(() => {
    if(userLogin){
      setState(userLogin.user.state)
      setDistrict(userLogin.user.district)
    }

  }, []);
  

  // useEffect(() => {
    
  //   async function getcoordinators() {
  //     const url = `${process.env.REACT_APP_URL}/getcoordinators`;
  //     const headers = reqHeader(true, true, true);
  //     console.log("state, district", state, district)
  //     const params = {
  //       state:state,
  //       district:district,
  //      };

  //     const config = { params, headers };
  //     await axios
  //       .get(url, config)
  //       .then((res) => {
  //         console.log("Coordinator", res.data.data);
  //         setCoordinators(
  //           res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
  //         );
  //         //console.log("From useEffect languages");
  //       })
  //       .catch((err) => {});
  //   }
  //   getcoordinators();
    
  // }, [district]); 

  
  // const onChangeCoordinator = (e) => {
  //   setValue('coordinator', e.target.value,  { shouldValidate: true })
  //   setCoordinator(e.target.value);
  
  //   async function getallFiles() {
  //     const url = `${process.env.REACT_APP_URL}/getallfiles`;
  //     const headers = reqHeader(true, true, true);
  //     console.log("header", headers)
  //     const params = {
  //       state, district, coordinator:e.target.value
  //     }
  //     console.log("params", params)
  //     const config = { params, headers };
  //     await axios
  //       .get(url, config)
  //       .then((res) => {
  //         const boolsorted = res.data.data.sort((a,b) => a.isQcAccepted - b.isQcAccepted);
  //         let sorted=boolsorted.sort((a, b) => a.status.localeCompare(b.status));
  //         setFileList(sorted);
  //         // const sorted = res.data.data.sort((a, b) => a.status.localeCompare(b.status))
  //         // let boolsorted=sorted.sort((a,b) => a.isQcAccepted - b.isQcAccepted)
  //         // setFileList(boolsorted);
  //         const times = [];
  //         res.data.data.map((filedata)=>{
  //           if(filedata.status == "Accepted"){
  //             times.push(filedata.fileDuration)
  //           }         
  //           })
  //           const sum = sumTime(times)
  //           setTotalDuration(sum)
  //       })
  //         .catch((err) => {});
  //   }
  //   getallFiles();

  // }


  const schema = {
    'SrNo': {
      // JSON object property name.
      prop: 'SrNo',
      type: Number
    },
    'File1': {
      // JSON object property name.
      prop: 'File1',
      type: String
    },
    'File2': {
      prop: 'File2',
      type: String,
    },
    'CosineSimilarity': {
      prop: 'CosineSimilarity',
      type: Number,
    },
  }
  

  // File.
useEffect(()=>{
  const input = document.getElementById('input')
  if(input){
    input.addEventListener('change', () => {
      
      readXlsxFile(input.files[0], { schema }).then(({ rows, errors }) => {
            // `errors` list items have shape: `{ row, column, error, reason?, value?, type? }`.
            //errors.length === 0
            setFileList(rows)
          })
          
    })
  }
},[]);

useEffect(()=>{
  async function getExcelFileDetails() {
    let counter = 0;
    //let rowArrayData = []
    // console.log("fileList.length", fileList.length)
    if(fileList){
      // await Promise.all(
      //   fileList.map(async(value, index)=>{
      //       let data = {
      //         SrNo: value.SrNo,
      //         File1: value.File1,
      //         File2: value.File2,
      //         CosineSimilarity: value.CosineSimilarity
      //       }
      //   })
      // )
      //console.log("fileList", fileList)
      const url = `${process.env.REACT_APP_URL}/getinterpairdetails`;
            const headers = reqHeader(true, true, true);
            const body = {
              fileList
            }
            const config = { headers };
            await axios
              .post(url, body, config)
              .then((res) => {
                counter = counter + 1;
                setCounter(counter)
                //rowArrayData.push(res.data.data)
                //console.log("res", res.data.data)
                setRowData(res.data.data)
              })
                .catch((err) => {
                  console.log("err", err)
                  //toast.error(err.response.data)
                }) 
    }

      }
      getExcelFileDetails()
},[fileList])

// console.log("rowData", rowData)  

  let columns = useMemo(
    () => [
      {
        Header: "SrNo",
        accessor: "SrNo",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File1",
        accessor: "File1",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Audio1",
        //accessor: "File1url",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.File1url} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "File2",
        accessor: "File2",
        sortType: "basic",
        filter: "text"
      },
      {
        Header: "Audio2",
        //accessor: "File2url",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.File2url} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "CosineSimilarity",
        accessor: "CosineSimilarity",
        sortType: "basic",
        filter: "text",
      }
    ],
    []
  );


  return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Inter Pair Check
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className ="inputWrap">
                      <input type="file" id="input" />
                     
                    </div>
                </div>

              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                  {/* {data} */}
                  <div>
                    <label>Counter:</label>
                    <span className="value"> {rowData.length}</span>
                  </div>
                
                </div>

                {/* <button className="btn blueBtn-fill">Excel Export</button> */}
              </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent">
                                    <Table
                                    columns={columns}
                                    data={rowData}
                                    count={rowData.length}
                                    pagination={true}
                                    expanded={false}
                                    isHeader={true}
                                    filter={true}
                                    isCountDisplayed={true}
                                    customText="Files"
                                  /> 
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default QcInterPairCheck;