import React, { useState, useMemo, useEffect } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
  useExpanded,
  useRowSelect,
} from "react-table";
import { SortIconUP, SortIcon } from "../icons.component";
import GlobalFilter from "./GlobalFilter.jsx";

const defaultPropGetter = () => ({});

const Table = ({
  columns,
  data,
  count,
  pagination,
  expanded,
  isHeader = false,
  filter,
  isCountDisplayed,
  customText,
  isMultiSelect = false,
  pageType,
  setSelectedRows = defaultPropGetter,
  setSelectedRowsValues = defaultPropGetter,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
}) => {
  const hideColumns = expanded ? [] : ["_id"];

  const hdnCols = columns
    .filter((column) => column?.hidden)
    .map((column) => column.accessor);

  if (hdnCols.length > 0) {
    hdnCols.forEach((value) => {
      hideColumns.push(value);
    });
  }

  let pageSizeArr = [];

  if (count <= 10) {
    pageSizeArr.push("10");
  } else if (count > 10 && count <= 25) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
  } else if (count > 25 && count <= 50) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
    pageSizeArr.push("50");
  } else if (count > 50) {
    pageSizeArr.push("10");
    pageSizeArr.push("25");
    pageSizeArr.push("50");
    pageSizeArr.push("100");
  }

  const defaultPageSize = pageType == "qcuserfilelist" ? 100 : 10;

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: hideColumns,
      pageSize: defaultPageSize,
      pageIndex: 0
      },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize, globalFilter, selectedRowIds } = state;

  // const [rowColor, setRowColor] = useState("");
  // const [selectedRowIndex, setSelectedRowIndex] = useState(null)

  useEffect(() => {
    if (isMultiSelect) {
      setSelectedRows(selectedRowIds);
      setSelectedRowsValues(selectedFlatRows);
    }
  }, [
    isMultiSelect,
    setSelectedRows,
    selectedRowIds,
    setSelectedRowsValues,
    selectedFlatRows,
  ]);

  // const handleRowClick = (i, row) => {
  //   if(i == row.id){
  //     return setRowColor("yellow")
  //   }else{
  //     return setRowColor("white")
  //   }
  // }

  // const handleChangeColor = (row, i) => {
  //   //setSelectedRowIndex(row.index)
  //   row.index === i ? setRowColor("yellow") : setRowColor("white")
  // }
  
  const paginationControl = pagination ? (
    <div className="paginationWrap d-flex">
      {/* {console.log("pageSize", pageSize, pageSizeArr, count)} */}
      <select
        className="showpages"
        value={pageSize}
        onChange={(e) => setPageSize(Number(e.target.value))}
        disabled={count <= 10}
      >
        {pageSizeArr.map((pageSize) => (
          <option key={pageSize} value={pageSize}>
            Show {pageSize}
          </option>
        ))}
      </select>
      <div>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
          {"  "}
        </span>
        <span>
          | Go to Page{" "}
          <span className="inputWrap">
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              onKeyPress={(e) => {
                var inputKeyCode = e.key;
                if (inputKeyCode != null) {
                  if (inputKeyCode == "-") e.preventDefault();
                }
              }}
            />
          </span>
        </span>
      </div>

      <div className="paginationAction">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </button>
        <button
          className="prevBtn"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          &lt; Prev
        </button>
        <button
          className="NextBtn"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          Next &gt;
        </button>
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </button>
      </div>
    </div>
  ) : (
    ""
  );

  // Render the UI for your table
  return (
    <div className="tableWrap projectTableWrap block">
      {isHeader ? (
        <header className="d-flex">
          {isCountDisplayed === true ? (
            <h2>
              {customText} <span className="projectCount">({rows.length})</span>
            </h2>
          ) : (
            ``
          )}

          {filter === false ? (
            ""
          ) : (
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              customText={customText}
            />
          )}
        </header>
      ) : (
        ""
      )}

      <div className="projectTable">
        {/* React Table Will come here */}
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps(),
                      [
                        {
                          className: column.className,
                          style: column.style,
                        },
                        getColumnProps(column),
                        getHeaderProps(column),
                      ]
                    )}
                  >
                    <span>{column.render("Header")}</span>
                    <span>
                      {/* {column.isSorted ? (
                        column.isSortedDesc ? (
                          <i className="fas fa-sort-down"></i>
                        ) : (
                          <i className="fas fa-sort-up"></i>
                        )
                      ) : (
                        <i className="fas fa-sort"></i>
                      )} */}
                      {column.isSorted ? (
                          column.isSortedDesc ? (
                            <span className="sortDown">
                              <SortIconUP />
                            </span>
                          ) : (
                            <span className="sortUp">
                              <SortIconUP />
                            </span>
                          )
                        ) : (
                          ""
                        )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              // console.log("row", row)
              prepareRow(row);
              return (
                <tr key={row.index} {...row.getRowProps(getRowProps(row))} style={{
                  backgroundColor: !row.isSelected ? "transparent" : "#e8b9224d" 
                 }}  >
                  {/* {console.log("Language rows", row)} */}
                  {row.cells.map((cell, i) => {
                    return (
                      <td key={i}
                        {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                          getColumnProps(cell.column),
                          getCellProps(cell),
                        ])}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* </div> */}
      </div>
      {paginationControl}
      {/* </div> */}
    </div>
  );
};

export default Table;
