import React , { useEffect, useState } from "react";
import { PasswordHide } from "../icons.component";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../../helper/reqHeader.js";
import axios from "axios";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
  mobile: yup
    .string() 
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('A mobile number is required'),
  email: yup
    .string()
    .required("Email is required!")
    .email("Enter a valid Email ID!"),
  state: yup
    .array()
    .nullable()
    .required("State is required."),
  
   
});


const AddManagerPopup = () => {

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue } = methods;

  // const {
  //   register,
  //   handleSubmit,
  //   control,
  //   trigger,
  //   formState: { errors },
  //   setValue,
  //   getValues
  // } = useForm({
  //   mode: "onSubmit",
  //   // reValidateMode: "onChange",
  //   resolver: yupResolver(schema)
  // });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);



  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("States popup", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);


  const onChangeState = (item) => {
    const stateList = item.map((vals) => vals.value);
    setValue("state", stateList);
    setState(item);
  }
  



  const submitHandler = async(data) => 
  {
         console.log("data", data);
         if(data.state.length == 0){
          toast.error("Please select states")
         }else{
          let managerstate = [];
          data.state.map((row)=>{
            let objstate = {"state": row}
            managerstate.push(objstate);
          })
         //console.log("managerstate", managerstate)

            const url = `${process.env.REACT_APP_URL}/addnewuser`;
            const headers = reqHeader(true, true, true);
      
            const config = {
                headers,
              };
    
            const body = {
              name:data.name,
              mobile:data.mobile,
              role:"Manager",
              email:data.email,
              managerStates: managerstate
            };
            console.log("body", body)
            try {
              const response = await axios.post(url, body, config);
              const result = await response.data;
              //  console.log("The user created is" + JSON.stringify(result));
              toast.success("Manager Added Successfully. Access code is sent to the manager's mobile number!!")
              setTimeout(()=>{
                window.location.reload();
              }, 3000)
              
              

            } catch (error) {
              //console.log(error.response.data.error);
              toast.error(error.response.data.error);
              //toast.error("Incorrect Details");
            }

         }  
     };

  return (
      <div className="addNewUserPop" style={{margin:"10px"}}>
          <ToastContainer />
        <br/>
        <form className="form" onSubmit={handleSubmit(submitHandler)}>
          <div className="popupForm">
            <div className="formRow child-2" >
              <div className="inputBlock">
                <label>Name<span className="requiredField">*</span></label>
                <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                  <input
                        type="text"
                        placeholder=" Full Name"
                        name="name"
                        ref={register}
                      />
                      {errors.name && (
                        <span className="inputErrorMsg">
                          {errors.name?.message}
                        </span>
                      )}
                 </div>
              </div>
              <div className="inputBlock">
                <label>Mobile Number</label>
                <div className={`inputWrap ${errors.mobile ? "error" : ""}`}>
                     <input
                        type="text"
                        placeholder="Mobile No"
                        name="mobile"
                        ref={register}
                      />
                      {errors.mobile && (
                        <span className="inputErrorMsg">
                          {errors.mobile?.message}
                        </span>
                      )}
                  </div>
              </div>
              {/* <div className="inputBlock">
                <label>State<span className="requiredField">*</span></label>
                <div className="inputWrap">
                  <input type="text" placeholder="Enter State" />
                </div>
              </div> */}

            </div>
            <div className="formRow child-2" style={{marginTop:"10px"}}>
              <div className="inputBlock">
                <label>Email<span className="requiredField">*</span></label>
                <div className={`inputWrap ${errors.email ? "error" : ""}`}>
                  <input type="text" 
                    placeholder="Enter Email"
                    name="email"
                    ref={register}
                   />
                  {errors.email && (
                        <span className="inputErrorMsg">
                          {errors.email?.message}
                        </span>
                      )}
                </div>
              </div>

              <div className="inputBlock">
                  <label>State<span className="requiredField">*</span></label>
                    <div className = {`inputWrap ${errors.state ? "error" : ""}`}>
                      <Controller
                         control={methods.control}
                         name="state"
                         defaultValue={{}}
                         render={({ onChange, value, name, ref }) => (
                          <Select
                          inputRef={ref}
                          name={name}
                          value={state}
                          onChange={onChangeState}
                          options={states}
                          isMulti={true}
                          isClearable
                          isSearchable={true}
                          placeholder={"Select States"}
                          getOptionValue={(option) => option.value}
                          getOptionLabel={(option) => option.value}
                          ref={register}
                         />
                        )}
                      />
                            {errors.state && (
                            <span className="inputErrorMsg">
                              {errors.state?.message}
                            </span>
                          )}
                    </div>
              </div>
            </div>

           
          </div>

          <div className="footerAction d-flex justify-center" style={{margin:"20px"}}>
            <button className="adminUserBtn btn">Submit</button>
          </div>
        </form>
      </div>
    );
};

export default AddManagerPopup;



