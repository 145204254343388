import React, { useEffect, useMemo, useState } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import axios from "axios";
import reqHeader from "../helper/reqHeader";
import Table from "./Table/Table";
import {
  Alert,
  CustomDialog,
  Confirm,
  Dialog,
  useDialog,
} from "react-st-modal";
import { useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import {BsPauseCircleFill} from "react-icons/bs"

const schema = yup.object().shape({
  coordinator: yup.string().required("Coordinator is required!"),
});

const QcSegmentationUserFileList = () => {
  const location = useLocation();
  const { mobile, name, age, gender, speakerID } = location.state;
  console.log("location.state", location.state);
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [imgSrc, setImgSrc] = useState([]);
  const [reasonforrejection, setReasonForRejection] = useState();
  const [userDetails, setUserDetails] = useState([]);
  const dialog = useDialog();
  const [reload, setReload] = useState(false);


  // const reloaded = (data) => {
  //   console.log("reloaded is done", data);
  //   if (data !== reload) {
  //     setReload(data);
  //   }
  // };

  console.log("reload", reload)

  useEffect(() => {
    if (reload) {
      window.location.reload();
    }
  }, [reload]);

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(() => {
    // disable right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    document.onkeydown = function (e) {
      // disable F12 key
      if (e.keyCode == 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode == 85) {
        return false;
      }
    };
  }, []);

  useEffect(() => {
    async function getUserDetails() {
      const url = `${process.env.REACT_APP_URL}/getqcacceptedfiles`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        mobile,
        speakerID,
      };
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)
          //setUserDetails(res.data.data);

          const boolsorted = res.data.data.sort(
            (a, b) => a.isQcAccepted - b.isQcAccepted
          );
          let sorted = boolsorted.sort((a, b) =>
            a.status.localeCompare(b.status)
          );

          setFileList(sorted);

          //disable all Accept Reject buttons
          sorted.map((frow) => {
            let acbtn = document.getElementById(`acpbtn${frow._id}`);
            acbtn.disabled = true;
            let rejectbtn = document.getElementById(`rejbtn${frow._id}`);
            rejectbtn.disabled = true;
          });

          const times = [];
          res.data.data.map((filedata) => {
            if (filedata.status == "Accepted") {
              times.push(filedata.fileDuration);
            }
          });
          const sum = sumTime(times);
          setTotalDuration(sum);
        })
        .catch((err) => {});
    }
    getUserDetails();
  }, []);


  const sumTime = (times) => {
    //console.log("Inside sumtime", times);
    let sumSeconds = 0;

    times.forEach((time) => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ":" + minutes + ":" + seconds;
  };

  const imageclick = (imgpath) => {
    //console.log("imgpath", imgpath);
    setImgSrc(imgpath);
  };

  const spanLinkStyle = {
    cursor: "pointer",
    color: "#22aaf0",
    textDecoration: "underline",
    fontWeight: "bold",
  };

  //Click on filename
  const rowClicked = (row) => {
    console.log("Row inside the rowClicked is ", row)

    let client = "gg_editor"

    let childWindow = window.open(`/${client}/${row._id}`, "_blank");

    var timer = setInterval(function () {
      if (childWindow.closed) {
        clearInterval(timer);
        setReload(true);
      }
    }, 1000);
  };

  let columns = useMemo(
    () => [
      {
        Header: "File Name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return  <span
          style={spanLinkStyle}
          onClick={() => rowClicked(row.original)}>
          {row.original.fileName}
        </span>
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div onClick={(e) => imageclick(row.original.imageLocation)}>
              {row.original.imageName}
            </div>
          );
        },
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.status == "Accepted" && row.original.isQcAccepted
                ? "Accepted"
                : row.original.status == "Accepted" &&
                  !row.original.isQcAccepted
                ? "QCPending"
                : row.original.status}
            </div>
          );
        },
      },
      {
        Header: "Reopen Status",
        accessor: "",
        sortType: "basic",
        filter: "text",
        //fixed: "left",
        Cell: ({ value, column, row }) => {
          //console.log("row", row)
          return row.original.reOpenedByQcPr ? "Reopened" : ""
        },
      },
    ],
    []
  );

  return (
    <>
      <div className="container entitiCustomerWrap d-flex">
        <LeftNavigation />
        <ToastContainer />
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">Quality Checker</div>

            <UserProfile />
          </div>

          <div>
            <div className="d-flex justify-between">
              <div className="adminDetails">
                <div>
                  <label>Total Audio Recorded:</label>
                  <span className="value">{totalDuration}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hover_img" style={{ textAlign: "end" }}>
            <a href="#">
              <span>
                <img
                  src={imgSrc}
                  alt=""
                  style={{ width: "200px", height: "200px" }}
                />
              </span>
            </a>
          </div>
          <div className="projectCoordinatorContent">
            <div className="projectContent">
              <Table
                columns={columns}
                data={fileList}
                count={fileList.length}
                pagination={false}
                expanded={false}
                isHeader={true}
                filter={true}
                isCountDisplayed={true}
                customText="Files"
                pageType="qcuserfilelist"
                //reload={reloaded}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QcSegmentationUserFileList;
