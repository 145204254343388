export default function validate(
  value,
  segduration,
  spkid,
) {
  let errors = [];
  let segmentdata = [];

  //check Blank segment
  // if (!value || value == "" || value == " ") {
  //   let SegmentDataEmpty = "Segment data is empty";
  //   segmentdata.push({ SegmentDataEmpty: SegmentDataEmpty });
  // }
  //more than 20 seconds check on segment length
  debugger;
  // if (segduration >= 10) {
  //   let TwentySecondError =
  //     "Segment is of more than ten seconds duration, please split into smaller segments";
  //   segmentdata.push({ TwentySecondError: TwentySecondError });
  // }

  if(value !== "<SIL>" && segduration <= 5){
    let fiveSecondError = "Segment should be more than 5 sec duration.";
    segmentdata.push({ fiveSecondError: fiveSecondError });
  }

  if(value !== "<SIL>" && segduration >= 10){
    let tenSecondError = "Segment should be less than 10 sec duration.";
    segmentdata.push({ tenSecondError: tenSecondError });
  }

  //Check Space before period
  let spaceperiodregex = /\s[.|?|!]/;
  let SpaceBeforePeriod = spaceperiodregex.test(value);
  if (SpaceBeforePeriod) {
    SpaceBeforePeriod = "Space found before punctuation mark";
    segmentdata.push({ SpaceBeforePeriod: SpaceBeforePeriod });
  }


  //Numerice value in text-area check
 // debugger;
  let numberregex = /[०-९]|[0-9]|[০-৯]|[૦-૯]|[೦-೯]|[൦-൯]|[୦-୯]|[੦-੯]|[௦-௯]|[౦-౯]|[۰-۹]/g
  //let numberregex = /\d/;
  let Overlapregex=/<OVERLAP\s*\S\d*>/g;
  let tempval=value;
  //console.log("tempval..",tempval);
  tempval=tempval.replace(Overlapregex,"");
  let numericValue = numberregex.test(tempval);
  
  if (numericValue) {
    //let Overlap=/\W\d/;
    numericValue = "Number in digits found";
    segmentdata.push({ numericValue: numericValue });
  }

  ////Special characters check
  //.!?,;:
  //debugger;
  //
  let newtext=value.replaceAll("</SEGMENT>","").replaceAll("</UNKNOWN_SEGMENT>","").replaceAll("[inaudible]","").replaceAll("[unintelligible]", "");

  let specialcharactersregex = /[\@#$%^&*()":{}\/`~;_\[\]]/g
  let SpecialCharacters = specialcharactersregex.test(newtext);

  if (SpecialCharacters) {
    if (!value.match(/([\,.'!?\-]+[\\]+\S*)/)) {
      SpecialCharacters = "Special characters found.";
      segmentdata.push({ SpecialCharacters: SpecialCharacters });
    }
  }

  ////Math symbols check
  //.!?,;:
  //debugger;
  //
  let mathsymbolssregex = /[\+\=]/g;
  let MathSymbols = mathsymbolssregex.test(value);

  if (MathSymbols) {
    MathSymbols = "Math symbols found.";
    segmentdata.push({ MathSymbols: MathSymbols });
  }

  // okay check
  // if (value.match("Okay") || value.match("okay") || value.match("OKAY")) {
  //   let Okaystring = "Invalid string 'okay' found. Please repalce it with 'OK'";
  //   segmentdata.push({ Okaystring: Okaystring });
  // }

  //debugger;
  //check openCloseMismatch
  let SEGopen = 0,
    SEGclose = 0,
    UNSEGopen = 0,
    UNSEGclose = 0,

    FILErrorFlag = false;

  let openCloseMismatchRegex = /[<]+\S.*?[>]/g;
  let getopenCloseString = value;
  let openCloseMismatch = openCloseMismatchRegex.test(value);
  const match = getopenCloseString.match(openCloseMismatchRegex);

  // console.log("value..",value);
  // console.log("openCloseMismatch..",openCloseMismatch);
  // console.log("match..",match);
  
  if (openCloseMismatch) {
    //debugger;
    match.map((match) => {
      if (getopenCloseString.includes("SEGMENT")) {
        if (match.includes("/SEGMENT")) {
          SEGclose++;
        } else if (match.includes("SEGMENT")) {
          SEGopen++;
        }
      }
      if (getopenCloseString.includes("UNKNOWN_SEGMENT")) {
        if (match.includes("/UNKNOWN_SEGMENT")) {
          UNSEGclose++;
        } else if (match.includes("UNKNOWN_SEGMENT")) {
          UNSEGopen++;
        }
      }
    });
    if (
      SEGopen != SEGclose ||
      UNSEGopen != UNSEGclose
    ) {
      openCloseMismatch = "Open close Angular tag mismatch found.";
      segmentdata.push({ openCloseMismatch: openCloseMismatch });
    }

    let angularemptyexpr = /[<]\S*[>]\s*[<]\S*[>]/g;
    // |[<]\S*[/>]
    //let spaceperiodregex = new RegExp(/(\s)\./);
    let emptyangulartags = angularemptyexpr.test(value);
    if (emptyangulartags) {
      let noerrorexpr = /[<\/]\S*[>]\s*[<]\S*[>]/g;
      if (!noerrorexpr) {
        emptyangulartags = "Empty angular tags found";
        segmentdata.push({ EmptyAngularTags: emptyangulartags });
      }
    }
  }

  ///validation for misplaced tags, close tag comes first
  let ntext = value.replaceAll("<SIL>",""); 
  //let openClosematch = openCloseMismatchRegex.test(value);
  const matches = ntext.match(openCloseMismatchRegex);
  //console.log("matches..",matches);
  let OC = [];
    
  if(matches && matches.length>0)
  {
    matches.map((match) => {
     
      // if (match.includes("/")) {
      //   OC.push("C");
      // } else {
      //   OC.push("O");
      // }
      //LM for LM,AW-AW,CP-CP,QT-MP,NS-NPS,RV-OVERLAP,ZU-SUP,WI-MUSIC
      
      if (match.includes("SEGMENT")) {
        if (match.includes("/SEGMENT")) {
          OC.push("T");
        } else if (match.includes("SEGMENT")) {
          OC.push("S");
        }
      }
      if (match.includes("UNKNOWN_SEGMENT")) {
        if (match.includes("/UNKNOWN_SEGMENT")) {
          OC.push("N");
        } else if (match.includes("UNKNOWN_SEGMENT")) {
          OC.push("U");
        }
      }
    });
    console.log("OC..",OC);
    if(OC)
    {
      for(let i=0; i<OC.length; i++)
      {
        //console.log("OC,OC length",OC,OC.length);
        //console.log("i..",i);
        //LM,AW,CP,QT,NS,RV,ZU,YI
        if((OC[i]=="S" && OC[i+1]=="T")||(OC[i]=="U" && OC[i+1]=="N"))
        {
          OC.splice(i,2);
          i=-1;
          continue;
          //OC.splice(i+1,1);
        }
        //else if(OC[i]=="O" && OC[i+1]=="C")
        console.log("OC after splice..",OC);
      }
      console.log("final OC..",OC);
      if(OC&&OC.length>0)
      {
        let InvalidAngTag = "Invalid Angular tags found";
        segmentdata.push({ InvalidAngularTag: InvalidAngTag });
      }
      
    }
    
    
  }
  
  //debugger;
  //angular bracket mismatch
  let angularbracketregex = new RegExp(
    /([\<]+\S.*?[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<]+\S.*?[\/>]+)/
  );
  /// |[\<]+\S.*?[\/>]+
  let Angulartag = angularbracketregex.test(value);
  let angTag = [
    "<SEGMENT>",
    "</SEGMENT>",
    "<UNKNOWN_SEGMENT>",
    "</UNKNOWN_SEGMENT>",
    "<SIL>"
  ];
  //debugger;
  if (Angulartag) {
    let cntOpen = value.split("<").length - 1;
    let cntClose = value.split(">").length - 1;
    if (cntOpen != cntClose) {
      let AngularMismatch = "Incorrect Angular bracket found";
      segmentdata.push({ AngularMismatch: AngularMismatch });
    } else {
      // AngularMismatch = "";
      // segmentdata.push({ AngularMismatch: AngularMismatch });
      let angflag = false;
      let angtagmatches = value.match(
        /([\<]+\S.*?[\>]+)|([\<\/]+\S.*?[\>]+)|\S[\>]+|[\<]+|[\>]|[\<]|([\<]+\S.*?[\/\>]+)/g
      );
      //console.log(angtagmatches);

      let InvalidAngularTag = "";
        //debugger;
      for (let i = 0; i < angtagmatches.length; i++) {
        if (angtagmatches[0] == "<") {
          angflag = true;
          break;
        }
        for (let j = 0; j < angTag.length; j++) {
          if (angtagmatches[i] === angTag[j]) {
            angflag = true;
            break;
          } else {
            angflag = false;
          }
        }
        if (!angflag)
          InvalidAngularTag = InvalidAngularTag + " " + "Invalid Angular tags";
      }

      if (InvalidAngularTag.match("Invalid Angular tags")) {
        InvalidAngularTag = "Invalid Angular tags found";
        segmentdata.push({ InvalidAngularTag: InvalidAngularTag });
      }
    }
    //letangularbracketregex2;
  }


  //debugger;
  if (
    value.includes(" SIL") |
    value.includes("S I L") |
    value.includes("SI L") |
    value.includes("S IL")
  ) {
    //if (!value.includes("<SIL/>")) {
    let InvalidSILTag = "Invalid SIL tag(s) found";
    segmentdata.push({ InvalidSILTag: InvalidSILTag });
    //}
  }

  if( value.includes("<SIL>") ){
    let silReg = /^[<SIL>]/
    let temp = value.replace("<SIL>", "")
    // let silCheck = silReg.test(value);
    // console.log("silCheck", silCheck)
    if(temp != "" && temp != " "){
      let InvalidSILTag = "Only <SIL> tag & single <SIL> is allowed";
      segmentdata.push({ InvalidSILTag: InvalidSILTag }); 
    }
  }

  //debugger;
  let firstlettercapregex = /(?<=(?:^|[.?!])\W*)[a-z]/g;
  let temp = value.replaceAll("[inaudible]","").replaceAll("[unintelligible]", "")
  let newlmtg = firstlettercapregex.test(temp);
    if(newlmtg)
    {
      let InvalidFirstLetter = "Please start each sentence with capital letter.";
      segmentdata.push({ InvalidFirstLetter: InvalidFirstLetter });
    }


//validation for Empty tags
let lmTag = /<SEGMENT>.*?<\/SEGMENT>|<UNKNOWN_SEGMENT>.*?<\/UNKNOWN_SEGMENT>|/g ; 
 //console.log("lmTag", lmTag)
 //console.log("value", value)
let lmmatchVal=value.match(lmTag);
//console.log("lmmatchVal", lmmatchVal)
if(lmmatchVal!==null && lmmatchVal!==undefined && lmmatchVal!=="") 
{   
for(var i=0; i<lmmatchVal.length; i++)
{  let flag=0;  
    //console.log("i", i)   
   let newtext=lmmatchVal[i].replace("<SEGMENT>","").replace("</SEGMENT>","").replace("<UNKNOWN_SEGMENT>","").replace("</UNKNOWN_SEGMENT>","").replace(" ","");  
   //console.log("newtext", newtext)                
   if( newtext.length==0 && lmmatchVal[i] != "" || lmmatchVal===null || lmmatchVal===undefined ||lmmatchVal==="")  
   {
     flag=1;
   }
   if(flag)
   {
     let InvalidBlankTag = "Blank tag found";
     segmentdata.push({ InvalidBlankTag: InvalidBlankTag });
     break;
 }
}
}
  
  // if (
  //   (spkid == "" || spkid == false || spkid == "defaultvalue" || spkid == "undefined")
  // ) {
  //   let speakerIdEmpty = "Speaker Id is not added for this segment";
  //   segmentdata.push({ speakerIdEmpty: speakerIdEmpty });
  // }


  errors.segmentdata = segmentdata;
  return errors;
}
