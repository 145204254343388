import React, { useEffect, useMemo, useState, useRef } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import axios from "axios";
import reqHeader from "../helper/reqHeader"
import Table from "./Table/Table";
import { Alert, CustomDialog, Confirm, Dialog, useDialog } from "react-st-modal";
import {useLocation} from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";


const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
})

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const IntraUserFileList = () => {
  const location = useLocation();
  const {speakerID, mobile} = location.state;
  console.log("location.state", location.state)
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });


  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [imgSrc, setImgSrc] = useState([]);
  const [intraonespeaker, setIntraOneSpeaker] = useState();
  const [selectedRowsValues, setSelectedRowsValues] = useState({});
  const [isAccepted, setIsAccepted] = useState(false);
  const [isHold, setIsHold] = useState(false)
  const [speakerCount, setSpeakerCount] = useState();
  const dialog = useDialog();

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
      // disable right click
      document.addEventListener('contextmenu', event => event.preventDefault());
   
      document.onkeydown = function (e) {
   
          // disable F12 key
          if(e.keyCode == 123) {
              return false;
          }
   
          // disable I key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
              return false;
          }
   
          // disable J key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
              return false;
          }
   
          // disable U key
          if(e.ctrlKey && e.keyCode == 85) {
              return false;
          }
      }
  },[])

  useEffect(()=>{
    async function getUserDetails() {
      const url = `${process.env.REACT_APP_URL}/getuserdetails`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        mobile, speakerID
      }
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)
          //setUserDetails(res.data.data);

          // const boolsorted = res.data.data.sort((a,b) => a.isQcAccepted - b.isQcAccepted);
          // let sorted=boolsorted.sort((a, b) => a.status.localeCompare(b.status));
          //setFileList(sorted);
          const times = [];
          const listOfFiles = [];
          let speakerArray = [];
          res.data.data.map((filedata)=>{
            if(filedata.status == "Accepted" || filedata.status == "QcRejected"){
              listOfFiles.push(filedata)
              speakerArray.push(filedata.intra1Speaker)
              times.push(filedata.fileDuration)
            }         
            })
            setFileList(listOfFiles)
            const sum = sumTime(times)
            setTotalDuration(sum)

            setSpeakerCount(speakerArray.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {}))
       })
        .catch((err) => {});
    }
    getUserDetails();

  },[])

  const sumTime = (times) => {
    //console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

  const imageclick = (imgpath) => {
    //console.log("imgpath", imgpath)
    setImgSrc(imgpath)
  }
  
  const onSubmitFile = async(fid,e) => {
      const headers = reqHeader(true, true, true);
      let intraspeakerdata = getValues("intraonespeaker"); 
     // console.log("intraspeakerdata", intraspeakerdata)
      let body={
        id:fid,
        intra1Speaker: intraspeakerdata,
        role: "Intra1",
        //intra1CheckStatus: "Accepted"
      }
      //console.log("body", body)
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/updateintraspeaker`;
      await axios
        .put(url,body,config)
        .then((res) => {
          //console.log("res", res)
          toast.success(res.data.msg);
          let acbtn= document.getElementById(e.target.id);
          acbtn.disabled=true;
          //window.location.reload();
        });
  };


  useEffect(()=>{
    if(selectedRowsValues.length > 0){

      let rows = selectedRowsValues.map((value, index) => {
        return value.original;
      });

      let rowValues = rows.map((value, index) => {
        if(value.intra1Speaker){
          return value.intra1Speaker;
        }
       
      });

      let rowUniqueSpeaker = [...new Set(rowValues)];

      // console.log("rowUniqueSpeaker", rowUniqueSpeaker)

      let selectedStages = rows.map((value) => {
        if(value.intra1CheckStatus == "Accepted"){
          return value.intra1CheckStatus
        }else if(value.intra1CheckStatus == "Hold"){
          return value.intra1CheckStatus
        }else if(value.isQcAccepted == true && value.status == "Accepted" ){
          return "QCAccepted"
        }
      });

      console.log("selectedStages", selectedStages);
      let uniqueStage = [...new Set(selectedStages)];
      console.log("uniqueStage..",uniqueStage);

      if(uniqueStage.length == 1){
        if(uniqueStage.toString() === "Accepted"){
          setIsAccepted(false)
          setIsHold(false)
        }else if(uniqueStage.toString() === "Hold"){
          setIsHold(false)
          setIsAccepted(false)
        }else if(uniqueStage.toString() === "QCAccepted" && rowUniqueSpeaker.length == 1 && rowUniqueSpeaker[0] != undefined){
          setIsHold(true)
          setIsAccepted(true)
        }else if(uniqueStage === "undefined" ){
          setIsHold(false)
          setIsAccepted(false)
        }else{
          setIsHold(false)
          setIsAccepted(false)
        }
      }else{
        setIsHold(false)
        setIsAccepted(false)
      }
    }

  },[selectedRowsValues])

  const handleFileAcceptBatch = async () => {
    let counter = 0;
    if(selectedRowsValues.length > 0){
      await Promise.all(
        selectedRowsValues.map(async(value, index) => {
          const headers = reqHeader(true, true, true);
          //console.log("value", value)
          let body={
            id: value.original._id,
            role: "Intra1",
            intra1CheckStatus: "Accepted"
          }

          console.log("body", body)
          const config = { headers };
          let url = `${process.env.REACT_APP_URL}/intraacceptrejectfile`;
      
            await axios
            .put(url,body,config)
            .then((res) => {
              console.log("res", res)
              counter = counter + 1;
            })
            .catch((err) => {
              console.log("err", err)
              //toast.error("Speaker not updated. Please update speaker")
            });

    
            //console.log("selectedRowsValues.length, counter", selectedRowsValues.length, counter)
            if (selectedRowsValues.length === counter) {
              toast.success("Files are Accepted successfully!");
              window.location.reload();
            }
           
        })
      )
    }
  };

  const handleFileHoldBatch = async () => {
    let counter = 0;
    if(selectedRowsValues.length > 0){
      await Promise.all(
        selectedRowsValues.map(async(value, index) => {
          const headers = reqHeader(true, true, true);
          //console.log("value", value)
          let body={
            id: value.original._id,
            role: "Intra1",
            intra1CheckStatus: "Hold"
          }

          console.log("body", body)
          const config = { headers };
          let url = `${process.env.REACT_APP_URL}/intraacceptrejectfile`;
      
          await axios
            .put(url,body,config)
            .then((res) => {
              console.log("res", res)
              counter = counter + 1;
            })
            .catch((err) => {});
    
            //console.log("selectedRowsValues.length, counter", selectedRowsValues.length, counter)
            if (selectedRowsValues.length === counter) {
              toast.success("Files are on Hold successfully!");
              window.location.reload();
            }
           
        })
      )
    }
  };

  const onQCAcceptFile = async(fid,isaccepted, e) => {
    let intraspeakerdata = getValues("intraonespeaker"); 
    const headers = reqHeader(true, true, true);
    let body = {}
    if(intraspeakerdata){
      //console.log("isaccepted", isaccepted)
      if(isaccepted){
        body={
          id:fid,
          qcaccepted:isaccepted,
          intra1Speaker: intraspeakerdata,
          role: "Intra1",
        }
      }else{
        body={
          id:fid,
          qcaccepted:isaccepted,
          intra1Speaker: intraspeakerdata,
          role: "Intra1",
        }
      }
      console.log("body", body)
  
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/qcacceptfile`;
      await axios
        .put(url,body,config)
        .then((res) => {
          //toast.success(res.data.msg);
          let acbtn= document.getElementById(e.target.id);
          acbtn.disabled=true;
          let rejectbtn=document.getElementById(`rejbtn${fid}`);
          if(rejectbtn){
            rejectbtn.disabled=true;
          }
        
          // window.location.reload();
        });
    }else{
      toast.error("Please select the Speaker before Accept!")
    }
      

};

const onQCRejectFile = async(fid, duration, userId, e) => {

  const headers = reqHeader(true, true, true);
    let reason = getValues("reasonforrejection");
    console.log("reason", reason)
  if(reason){
    let body={
      id:fid,
      reason:reason,
      role: "Intra1",
      fileDuration: duration,
      userID: userId,
      qcaccepted:false
    }
    console.log("body", body)
    const config = { headers };
    let url = `${process.env.REACT_APP_URL}/rejectfile`;

    await axios
      .put(url,body,config)
      .then((res) => {
        
        let acbtn= document.getElementById(`acpbtn${fid}`);
        acbtn.disabled=true;
        let rejectbtn=document.getElementById(e.target.id);
        rejectbtn.disabled=true;
        // window.location.reload();
      });
  }else{
    toast.error("Please select reason for rejection")
  }
    
};


  let multiSelect = true;
  const checkBoxCols = multiSelect
  ? {
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <div>
          <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    }
  : {
      id: "_id",
      Header: "Id",
      accessor: "_id",
      sortType: "basic",
      filter: "text",
    };

  let columns = useMemo(
    () => [
     checkBoxCols,
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div onClick={(e) =>imageclick(row.original.imageLocation)}>
              {row.original.imageName}
            </div>
          );
        }
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Intra1 Speaker",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            row.original.intra1Speaker ? row.original.intra1Speaker : row.original.status == "Accepted" ?
            <div>
                <div className = {`inputWrap ${
                         errors.intraonespeaker ? "error" : ""
                      }`}>
                      <select
                          {...register("intraonespeaker")}
                          onChange={(e) => setValue('intraonespeaker', e.target.value,  { shouldValidate: true })} // Using setValue
                        >
                           <option value="">Please select Speaker</option>
                           <option value="Speaker1">Speaker1</option>
                           <option value="Speaker2">Speaker2</option>
                           <option value="Speaker3">Speaker3</option>
                        </select>
                        {errors.intraonespeaker && (
                        <span className="inputErrorMsg">
                          {errors.intraonespeaker?.message}
                        </span>
                      )}
                    </div>
            </div> : row.original.status == "QcRejected" ? "" : "" 
          );
        },
      },
      // {
      //   Header: "Submit",
      //   accessor: "",
      //   sortType: "basic",
      //   filter: "text",
      //   // fixed: "left",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       row.original.intra1Speaker ? " " :
      //       <div>
      //         <button id={`acpbtn${row.original._id}`}              
      //           onClick={(e) => onSubmitFile(row.original._id, e)}
      //           className="btn blueBtn-fill">
      //           Submit
      //         </button>

      //       </div>
      //     );
      //   }
      // },
      {
        Header: "Accept",
        //accessor: "qcaccept",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            (row.original.status == "Accepted"  && !row.original.isQcAccepted) || (row.original.isQcAccepted && !row.original.intra1Speaker) ?
            <div>
              <button id={`acpbtn${row.original._id}`}            
                onClick={(e) => onQCAcceptFile(row.original._id, row.original.isQcAccepted, e)}
                className="btn blueBtn-fill">
                Accept
              </button>

            </div> : ""
          );
        }
      },
      {
        Header: "Reason for rejection",
        //accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            row.original.status == "Accepted" && (!row.original.isQcAccepted)?
            <div>
                <div className = {`inputWrap ${
                         errors.reasonforrejection ? "error" : ""
                      }`}>
                      <select
                          {...register("reasonforrejection")}
                          onChange={(e) => setValue('reasonforrejection', e.target.value,  { shouldValidate: true })} // Using setValue
                        >
                           <option value="">Please select reason</option>
                           <option value="Audio > 25 seconds">Audio &gt; 25 seconds</option>
                           <option value="Audio < 10 seconds">Audio &lt; 10 seconds</option>
                           <option value="Not audible">Not audible</option>
                           <option value="Background Noise">Background Noise</option>
                           <option value="Gender mismatch">Gender mismatch</option>
                           <option value="Image & Audio mismatch">Image & Audio mismatch</option>
                           <option value="Speech is not natural">Speech is not natural</option>
                           <option value="Multiple speakers">Multiple speakers</option>
                           <option value="Abusive language">Abusive language</option>
                           <option value="Language Mismatch">Language Mismatch</option>
                           <option value="Different ID same Speaker">Different ID same Speaker</option>
                           {/* <option value="Same ID different voice">Same ID different voice</option>
                          */}
                           <option value="Voice below 20 age">Voice below 20 age</option>
                           
                        </select>
                        {errors.reasonforrejection && (
                        <span className="inputErrorMsg">
                          {errors.reasonforrejection?.message}
                        </span>
                      )}
                </div>
            </div> : ""
          );
        },
      },
      {
        Header: "Reject",
        accessor: "",
        sortType: "basic",
        filter: "text",
        // fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            row.original.status == "Accepted" && (!row.original.isQcAccepted)?
            <div>
              <button id={`rejbtn${row.original._id}`}            
                onClick={(e) => onQCRejectFile(row.original._id, row.original.fileDuration, row.original.userID, e)}
                className="btn blueBtn-fill">
                Reject
              </button>

            </div> : ""
          );
        }
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.intra1CheckStatus ? "Intra1" + " " + row.original.intra1CheckStatus : row.original.status == "Accepted" && row.original.isQcAccepted ? "QCAccepted" : row.original.status == "Accepted" ? "QCPending" : row.original.status}
            </div>
          );
        },
      },
    ],
    []
  );


    return (
      <>
       
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Intra1 Check
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between">
                <div className="adminDetails">
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                  <div>
                    <label>Speaker Counts:</label> <br />
                    <label>Speaker1:</label> <span className="value">{speakerCount && speakerCount.Speaker1}</span> <br />
                    <label>Speaker2:</label> <span className="value">{speakerCount && speakerCount.Speaker2}</span> <br />
                    <label>Speaker3:</label> <span className="value">{speakerCount && speakerCount.Speaker3}</span> <br />
                  </div>
                </div>

              </div>
            </div>
            <div className="hover_img" style={{textAlign:"end"}}>
            <a href="#"><span><img src={imgSrc} alt="" style={{width:"200px", height:"200px"}}/></span></a>
            </div>
            <div className="fileListTable">
            <div className="tableTopHeader" style={{display:"inline-flex"}}>
              {/* {console.log("!isSpeaker1, !isSpeaker2, !isSpeaker3", isSpeaker1, isSpeaker2, isSpeaker3)} */}
              <button className="btn blueBtn-fill" style={{marginLeft:"10px"}} onClick={handleFileAcceptBatch} disabled={!isAccepted} >Accept</button>
             
              <button className="btn blueBtn-fill" style={{marginLeft:"10px"}}  onClick={handleFileHoldBatch} disabled={!isHold}>Hold</button>
             
                </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isMultiSelect={multiSelect}
                      setSelectedRowsValues={setSelectedRowsValues}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );

};

export default IntraUserFileList;