import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { Delete, Pause, Play, Refresh, Stop } from "./icons.component";
import BannerImg from '../assets/images/banner.jpg';
import axios from "axios";
import reqHeader from "../helper/reqHeader"
import getBlobDuration from 'get-blob-duration'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import Start from "../assets/images/start.png"
import pausing from "../assets/images/pause.png"
import stopping from "../assets/images/stop.png"
import activePlay from "../assets/images/activePlay.png"
import activePause from "../assets/images/activePause.png"
import activeStop from "../assets/images/activeStop.png"
import Logo from '../assets/images/Logo.svg';
import {BiMenuAltRight} from "react-icons/bi"

// let gumStream = null;
// let recorder = null;
// let audioContext = null;

const AudioRecordView = () => {

  const userLogin = useSelector((state) => state.userLogin);

//   const [random, setRandom] = useState("");
//   const [audioUrl, setAudioUrl] = useState([]);
//   const [customBlob, setCustomBlob] = useState([]);
//   const [fileData, setFileData] = useState([])
//   const [pendingHours, setPendingHrs] = useState();
//   const [recordedHours, setRecordedHours] = useState();
//   // const [pinCodeHours, setPinCodeHours] = useState();
//   // const [pincodeLimit, setPincodeLimit] = useState();
//   // const [processing, setProcessing] = useState('');
//   // const [imageProcessing, setImageProcessing] = useState('');
//   const timer = document.getElementById('stopwatch');
//   let recordButton = document.getElementById("recordButton");
//   let stopButton = document.getElementById("stopButton");
//   let pauseButton = document.getElementById("pauseButton");
//   let submitButton = document.getElementById("submitButton");
//   let regenerateImageButton = document.getElementById("regenerateImageButton");

//   let hr = 0;
//   let min = 0;
//   let sec = -1;
//   let stoptime = true;


//   useEffect(() => {
//     async function getfileDetails() {
//     const url = `${process.env.REACT_APP_URL}/getfiledetails`;
//     const headers = reqHeader(true, true, true);
  
//     const config = { headers };
//     await axios
//       .get(url, config)
//       .then((res) => {
//         //console.log("res.data.data", res.data.data)
//         let pendhrs=parseInt(res.data.data.pendingHrs.split(":")[0]);
//         //console.log("pendhrs",pendhrs);
//         if(pendhrs>0){
//           setPendingHrs("00:00:00");
//         }
//         else{
//           setPendingHrs(res.data.data.pendingHrs);
//         }
//         setFileData(res.data.data.fileDetails);
//         //setPendingHrs(res.data.data.pendingHrs)
//         setRecordedHours(res.data.data.recordedHrs);
//         // setPinCodeHours(res.data.data.pincodeHrs);
//         // setPincodeLimit(res.data.data.limitPerPincode);
//      })
//       .catch((err) => {});
//   }
//   getfileDetails();
// }, []);

//   useEffect(async() => {
//     const url = `${process.env.REACT_APP_URL}/getrandomimagepath`;
//     const headers = reqHeader(true, true, true);
  
//     const params={
//       state:userLogin.user.state,
//       district:userLogin.user.district
//     }
//     const config = { params,headers };
//     await axios
//       .get(url, config)
//       .then((res) => {
//         console.log("imgpath", res.data.data[0].imgLocation)
//         setRandom(res.data.data[0].imgLocation)
//      })
//       .catch((err) => {});
//   },[])

//   //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
//   useEffect(()=>{
//       // disable right click
//       document.addEventListener('contextmenu', event => event.preventDefault());
   
//       document.onkeydown = function (e) {
   
//           // disable F12 key
//           if(e.keyCode == 123) {
//               return false;
//           }
   
//           // disable I key
//           if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
//               return false;
//           }
   
//           // disable J key
//           if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
//               return false;
//           }
   
//           // disable U key
//           if(e.ctrlKey && e.keyCode == 85) {
//               return false;
//           }
//       }
//   },[])

//   const generateImage = () => {
//     async function getRandomImage() {
//       const url = `${process.env.REACT_APP_URL}/getrandomimagepath`;
//       const headers = reqHeader(true, true, true);
//       console.log("header", headers)
//       const params={
//         state:userLogin.user.state,
//         district:userLogin.user.district
//       }
//       const config = { params,headers };
//       await axios
//         .get(url, config)
//         .then((res) => {
//           console.log("res.data.data", res.data.data[0].imgLocation)
//           setRandom(res.data.data[0].imgLocation)
//        })
//         .catch((err) => {});
//     }
//     getRandomImage();
//   };

//   //console.log("pincodeLimit", pincodeLimit);

//   const startRecording = () => {
//     let minRecorded = parseInt(recordedHours.split(":")[1]);
//     if(minRecorded >= 18){
//       toast.error("You have already completed 18 minutes recording!")
//       recordButton.disabled = true;
//     }
//     // else if(pinCodeHours > pincodeLimit){
//     //   toast.error("Recording for this Pincode is already completed!")
//     //   recordButton.disabled = true;
//     // }
//     else{
//       regenerateImageButton.disabled = true;
//       let constraints = {
//         audio: true,
//         video: false
//       };
//       timer.style.color = "#78CF4D"
//       recordButton.disabled = true;
//       stopButton.disabled = false;
//       pauseButton.disabled = false;
  
//       audioContext = new window.AudioContext({
//         sampleRate: 16000,
//       });
//       //console.log("sample rate: " + audioContext.sampleRate);
  
//       navigator.mediaDevices
//         .getUserMedia(constraints)
//         .then(function (stream) {
//           console.log("initializing Recorder.js ...");
  
//           gumStream = stream;
  
//           let input = audioContext.createMediaStreamSource(stream);
//           //console.log("input", input)
  
//           recorder = new window.Recorder(input, {
//             numChannels: 1,
//             sampleBits: 16, // 8 or 16
//             //bufferLen: 4096,
//             mimeType: "audio/wav",
//           });
  
//           recorder.record();
         
//           if (stoptime == true) {
//             stoptime = false;
//             timerCycle();
//           }
//         })
//         .catch(function (err) {
//           //enable the record button if getUserMedia() fails
//           timer.style.color = "#7C7F85"
//           recordButton.disabled = false;
//           stopButton.disabled = true;
//           pauseButton.disabled = true;
//         });
//     }
//   };

//   const pauseRecording = () => {
//     // console.log("pauseButton clicked rec.recording=", recorder && recorder.recording);
//     if(!recorder){
//       toast.error("Recorder not started! Please start recording first!")
//     }else{
//       console.log("recorder.recording", recorder.recording)
//       if (recorder.recording) {
//         //pause
//         timer.style.color = "#7C7F85"
//         recorder.stop();
//         if (stoptime == false) {
//           stoptime = true;
//         }
//         timer.style.color = "#7C7F85"
//         pauseButton.innerHTML = "Resume";
//       } else {
//         //resume
//         recorder.record();
//         if (stoptime == true) {
//           stoptime = false;
//           timerCycle();
//         }
//         pauseButton.innerHTML = "Pause";
//       }
//     }

//   };

//   const stopRecording = () => {
//     if(!recorder){
//       toast.error("Recorder not started! Please start recording first!")
//     }else{
//       //disable the stop button, enable the record too allow for new recordings
//       stopButton.disabled = true;
//       recordButton.disabled = false;
//       pauseButton.disabled = true;

//       //reset button just in case the recording is stopped while paused
//       pauseButton.innerHTML="Pause";

//       recorder.stop(); //stop microphone access
//       console.log("stoptime", stoptime)
//       timer.style.color = "#EE6054"
//       if (stoptime == false) {
//         stoptime = true;
//       }
//       gumStream.getAudioTracks()[0].stop();
    
//       recorder.exportWAV(createDownloadLink);

//       resetTimer();
//     }

//   };

//   const timerCycle = () => {
//     if (stoptime == false) {
//     sec = parseInt(sec);
//     min = parseInt(min);
//     hr = parseInt(hr);

//     sec = sec + 1;

//     if (sec == 60) {
//       min = min + 1;
//       sec = 0;
//     }
//     if (min == 60) {
//       hr = hr + 1;
//       min = 0;
//       sec = 0;
//     }

//     if (sec < 10 || sec == 0) {
//       sec = '0' + sec;
//     }
//     if (min < 10 || min == 0) {
//       min = '0' + min;
//     }
//     if (hr < 10 || hr == 0) {
//       hr = '0' + hr;
//     }

//     timer.innerHTML = hr + ':' + min + ':' + sec;


//     //console.log("timer, pendingHours", timer, pendingHours)

//     // if(pendingHours == (hr + ':' + min + ':' + sec)){
//     //   stopRecording();
//     //   toast.success("You have completed recording of your target time! Please stop recording")
//     // }

//     setTimeout(()=>{
//       timerCycle();
//     },1000);
//   }
// }

//   const resetTimer = () => {
//     timer.innerHTML = "00:00:00";
//     stoptime = true;
//     hr = 0;
//     sec = 0;
//     min = 0;
//   }


//   const createDownloadLink = async(blob) => {
//     console.log("blob", blob)
//     setCustomBlob([...customBlob, blob]);
//     var url = URL.createObjectURL(blob);
//     setAudioUrl([...audioUrl, url])
//   };

//   const deleteUrl = (url, indx) => {
//     console.log("url", url)
//     let auURL = audioUrl.filter((urls)=> {return urls !== url});
//     let deleteblob = customBlob.filter((blobs, index)=> {return index !== indx});
//     setCustomBlob(deleteblob);
//     setAudioUrl(auURL);
//     submitButton.disabled = false;
//     if(audioUrl.length > 0){
//       regenerateImageButton.disabled = true;
//     }else{
//       regenerateImageButton.disabled = false;
//     }
//   }


// //   const submitHandler = async(data) => {
// //     const file = customBlob[0];
// //     const duration = await getBlobDuration(file);

// //     try{
// //       submitButton.disabled = true;
// //       if(duration > 25 || duration < 10){
// //         toast.error("Recording should not be <10 or >25 seconds. Please try again")
// //       }else if(audioUrl.length > 1){
// //         toast.error("You can submit only one recording at a time!")
// //       }else{
// //         // Verifiy Audio
// //         const form = new FormData();
// //         form.append("audio", customBlob[0]);
// //         form.append("sampleAudioUrl", userLogin.user.sampleAudioPath);
// //         console.log("form", customBlob[0], userLogin.user.sampleAudioPath)
// //         const res = await axios.post("https://prodinterintrasegapi-yzuukhdvga-as.a.run.app/api/intra-audio-compare", form);
// //         //console.log("res", res)
// //         const isSameSpeaker = res?.data?.isSameSpeaker;
// //         //console.log("isSameSpeaker", isSameSpeaker)
 
// //         if (!isSameSpeaker) {
// //           toast.error("Different Voice or Background noise detected! Please try again")
// //           return;
// //         }

// //         const speechDuration = res?.data?.speechDuration;
        

// //         const userdistrict = userLogin.user.district;
// //         const userstate = userLogin.user.state;
     
// //         //console.log("file", file)
// //         const imgPath = process.env.REACT_APP_ENVIRONMENT == "production" ? "https://storage.googleapis.com/image-audio-recording/Images/" : "https://storage.googleapis.com/staging-image-audio-recording/Images/";
// //         //console.log("imgPath", imgPath)

// //         const randomImage = random.split(imgPath + userstate.replace(/[^a-z\d]+/gi, "") + "_" + userdistrict.replace(/[^a-z\d]+/gi, "") + "/")
// //         //console.log("randomImage", randomImage)
// //         const imagename = randomImage[1];
// //         // console.log("imagename", imagename)
// //         // console.log("file details", userLogin, userdistrict, random, imagename)
  
// //         const headers = reqHeader(true, true, true);
  
// //         const url = `${process.env.REACT_APP_URL}/uploadfiles`;
    
// //         const body = {
// //           imagename,
// //           duration,
// //           speechDuration
// //         }
// //         const config = {
// //           headers,
// //         };
// //         //console.log("body", body)
// //         const { data } = await axios.post(url, body, config);
  
// //         //console.log("data", data.data.url)
// //         const signedUrl = data.data.url 
  
// //       const sendfile = new Promise((resolve, reject) => {
// //           const xhr = new XMLHttpRequest();
    
// //           xhr.onreadystatechange = () => {
// //             if (xhr.readyState === 4) {
// //               if (xhr.status === 200) {
// //                 resolve(signedUrl);
// //               } else {
// //                 reject({
// //                   status: xhr.status,
// //                   statusText: xhr.statusText,
// //                 });
// //               }
// //             }
// //           };
    
// //           xhr.upload.onprogress = (evt) => {
// //             // For uploads
// //             if (evt.lengthComputable) {
// //               let percentComplete = ((evt.loaded / evt.total) * 100).toFixed(2);
// //               // setProgress(percentComplete);
// //               //console.log("progress uploading ", percentComplete);
// //             }
// //           };
    
// //           xhr.onloadend = async function () {
// //             // setLoading(false);
// //             toast.success(`File Uploaded Successfully !!!`);
// //             setTimeout(() => {
// //             window.location.reload();
// //             }, 2000);
// //           };
    
// //           xhr.open("PUT", signedUrl);
// //           xhr.setRequestHeader("Content-type", "application/octet-stream");
// //           xhr.setRequestHeader("Access-Control-Allow-Origin", "*");
// //           xhr.send(file);
// //         });
// //       }

// //     }catch(err){
// //       toast.error(err.response.data.error)
// //       console.log("err", err.response)
// //     }
// // }

// const submitHandler = async (data) => {
//   const file = customBlob[0];
//   const duration = await getBlobDuration(file);
//   try {
//     submitButton.disabled = true;
//     if (duration > 25 || duration < 10) {
//       toast.error("Recording should not be <10 or >25 seconds. Please try again")
//     } else if (audioUrl.length > 1) {
//       toast.error("You can submit only one recording at a time!")
//     } else {

//       const userdistrict = userLogin.user.district;
//       const userstate = userLogin.user.state;

//       const imgPath = process.env.REACT_APP_ENVIRONMENT === "production" ? "https://storage.googleapis.com/image-audio-recording/Images/" : "https://storage.googleapis.com/staging-image-audio-recording/Images/";
//       const randomImage = random.split(imgPath + userstate.replace(/[^a-z\d]+/gi, "") + "_" + userdistrict.replace(/[^a-z\d]+/gi, "") + "/")
//       const imagename = randomImage[1];

//       const headers = reqHeader(true, true, true);

//       const form = new FormData();
//       form.append("audio", customBlob[0]);
//       form.append("imagename", imagename);
//       form.append("duration", duration);

//       const url = `${process.env.REACT_APP_URL}/uploadfiles`;


//       const config = {
//         headers,
//       };
//       //console.log("body", body)
//       await axios.post(url, form, config);
//       toast.success("Recording submitted successfully!")
//       window.location.reload();
//     }

//   } catch (err) {
//     console.log("err", err.response)
//     toast.error(err.response.data.error)
//   }
// }


  const menuToggler = () =>{
    const leftSideBar = document.querySelector(".leftSideBar")
    leftSideBar.style.display = "block"
  }

    return (
      <>
      <div className="container generateImagePage d-flex mobileView" style={{display:"block", textAlign:"center", marginTop:"50px", fontSize:"16px", color:"white"}}>
      <img src={Logo} alt="Logo" className="logoImg"/>
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Welcome {userLogin.user.name}
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>
            <div className="recordingListWrap">
              <div className="listHead d-flex">
                <strong>Please use Megdap Recorder mobile application for Recording. Connect with Megdap Admin for further details.</strong>
              </div>
            </div>
          </div>
      </div>

        {/* <div className="container generateImagePage d-flex mobileView">
        <ToastContainer />

        <img src={Logo} alt="Logo" className="logoImg"/>
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Welcome {userLogin.user.name}
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>

           
            <div className="imgGenerateWrap">
              <img src={random} alt="" />
              <span id="stopwatch">00:00:00</span>
              <button className="blackBtn" id="regenerateImageButton" disabled={audioUrl.length > 0} onClick={generateImage}> <Refresh /> Regenerate Image</button>

            </div>
           

            <ul className="recordingBtns">
              <button className="btn btnGreen-small" onClick={startRecording} id="recordButton">
                <img src={Start} alt="Start"/>
                <img src={activePlay} alt="activePlay" className="activeShow" /> Start recording</button>
              <button className="btn greyBtn" onClick={pauseRecording} id="pauseButton">
              <img src={pausing} alt="pausing"/> 
              <img src={activePause} alt="activePause" className="activeShow"/> Pause recording</button>
              <button className="btn btnRed-small" onClick={stopRecording} id="stopButton">
              <img src={stopping} alt="stopping"/>
              <img src={activeStop} alt="activeStop" className="activeShow"/>  Stop recording </button> 
            </ul>

            <div className="recordingListWrap">
              <div className="listHead d-flex">
                <div>Recorded Audio</div>
                <div className="remainingTimeWrap" style={{paddingLeft:"18px", borderLeft:"1px solid white"}}>Remaining minute <span className="remainingTime">{pendingHours}</span></div>
              </div>
              <div id="recordingsList"></div>

              <ul className="recordList">
                {audioUrl.map((url, index)=>{
                    return (
                      <li className="recordItem recentAudio" key={index}>
                        <div className="audioFile">
                        
                          <audio src={url} controls controlsList="nodownload" capture="microphone"></audio>
                       
                        </div>
                        <div className="audioName"> Audio {index + 1} </div>
                       
                        <div className="audioDelete" onClick={(e)=> deleteUrl(url, index)}><Delete /> </div>
                      </li>
                    )
                  })
                }
                
           
              </ul>

             
              <div className="listFooter">
                <button className="btn blueBtn-fill" id="submitButton" disabled={audioUrl.length == 0} onClick={submitHandler}>submit</button>
              </div>


            </div>

            <div className="recordingListWrap">
              <div className="listHead d-flex">
                <div>Submitted Files</div>
                <div className="remainingTimeWrap" style={{paddingLeft:"18px", borderLeft:"1px solid white"}}>Total minutes <span className="remainingTime">{recordedHours}</span></div>
              </div>

              <ul className="recordList">
                {fileData && fileData.map((filedata, index)=>{
                  return(
                    <li className="recordItem" key={index}>
                    <div className="audioFile"> 
                       <audio src={filedata.fileLocation} controls controlsList="nodownload"></audio>
                      
                    </div>
                    <div className="audioName">{filedata.fileName.replace(/^(?:[^_]*_){3}/g, '')}</div>
                    <div className="audioTime">{filedata.fileDuration}</div>
                    <div className="audioTime">{format(
                    new Date(filedata.recordedOn),
                    "dd/MM/yyyy"
                  )}</div>
                    <div className="fileStatus">{filedata.status == "Accepted" && filedata.isQcAccepted ? "Accepted" : filedata.status == "Accepted" && !filedata.isQcAccepted ? "QC Pending"  : "Rejected" }
                    
                    </div>
                    <div className="audioDelete">{filedata.status == "Accepted" && filedata.isQcAccepted ? "" : filedata.status == "QcRejected" ? filedata.qcRejectionReason : filedata.status == "SupervisorRejected" ? filedata.supervisorRejectionReason :   filedata.status == "CoordinatorRejected" ? filedata.coordinatorRejectionReason : "" }</div>
                  </li>
                  )
                })}

              </ul>
            </div>


          </div>
        </div>*/}
      </> 
    );

};

export default AudioRecordView;