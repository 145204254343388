import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";


const TeamLeadSupervisorList = () =>  {
  const [supervisors, setSupervisors] = useState([]);
  //const userLogin = useSelector((state) => state.userLogin);

  useEffect(()=>{
    async function getTeamleadSupervisorList() {
      const url = `${process.env.REACT_APP_URL}/getteamleadsupervisorlist`;
      const headers = reqHeader(true, true, true);
     
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("setSupervisors", res.data.data);
         setSupervisors(res.data.data);
        })
        .catch((err) => {});
    }
    getTeamleadSupervisorList();
  },[])

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "State",
        accessor: "state",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "District",
        accessor: "district",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Password",
        accessor: "accesscode",
        sortType: "basic",
        filter: "text",
      },
      // {
      //   Header: "Edit",
      //   accessor: "id",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div>
      //           <Link onClick={async (e) => {
      //             const result = await CustomDialog(
      //               <PopupEditSupervisor supervisorID={row.original.id} />,
      //               {
      //                 title: "Edit Supervisor(Assign Districts)",
      //                 showCloseIcon: true,
      //               }
      //             );
      //           }}>Edit</Link>
      //       </div>
      //     );
      //   },
      // }
    ],
    []
  );


    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                user Details
              </div>

              <UserProfile />
            </div>

            <div className="d-flex justify-end regionSelectWrap">

              </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={supervisors}
                      count={supervisors.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Supervisor-List"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default TeamLeadSupervisorList;