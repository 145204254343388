import React, { useState, useRef } from "react";
import { Delete } from "./icons.component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Start from "../assets/images/start.png";
import pausing from "../assets/images/pause.png";
import stopping from "../assets/images/stop.png";
 
let gumStream = null;
let recorder = null;
let audioContext = null;
 
const RegisterAudio = ({ handleAudioBlobData }) => {
    const [audioUrl, setAudioUrl] = useState([]);
    const [customBlob, setCustomBlob] = useState([]);
    const timerRef = useRef(null);
    const recordButtonRef = useRef(null);
    const stopButtonRef = useRef(null);
    const pauseButtonRef = useRef(null);
 
    let hr = 0;
    let min = 0;
    let sec = -1;
    let stoptime = true;
 
    const startRecording = () => {
 
        if (audioUrl.length >= 1) {
            toast.error("You can record only one audio!");
            return;
        }
        let constraints = {
            audio: true,
            video: false,
        };
        if (timerRef.current) {
            timerRef.current.style.color = "#78CF4D";
        }
 
        if (recordButtonRef.current && stopButtonRef.current && pauseButtonRef.current) {
            recordButtonRef.current.disabled = true;
            stopButtonRef.current.disabled = false;
            pauseButtonRef.current.disabled = false;
        }
 
        audioContext = new window.AudioContext({
            sampleRate: 16000,
        });
 
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then(function (stream) {
 
 
                gumStream = stream;
 
                let input = audioContext.createMediaStreamSource(stream);
 
                recorder = new window.Recorder(input, {
                    numChannels: 1,
                    sampleBits: 16,
                    mimeType: "audio/wav",
                });
 
                recorder.record();
 
                if (stoptime === true) {
                    stoptime = false;
                    timerCycle();
                }
            })
            .catch(function (err) {
                if (timerRef.current) {
                    timerRef.current.style.color = "#7C7F85";
                }
                if (recordButtonRef.current && stopButtonRef.current && pauseButtonRef.current) {
                    recordButtonRef.current.disabled = false;
                    stopButtonRef.current.disabled = true;
                    pauseButtonRef.current.disabled = true;
                }
            });
    };
 
    const pauseRecording = () => {
        if (!recorder) {
            toast.error("Recorder not started! Please start recording first!");
        } else {
 
            if (recorder.recording) {
                //pause
                if (timerRef.current) {
                    timerRef.current.style.color = "#7C7F85";
                }
                recorder.stop();
                if (stoptime === false) {
                    stoptime = true;
                }
                if (timerRef.current) {
                    timerRef.current.style.color = "#7C7F85";
                }
                if (pauseButtonRef.current) {
                    pauseButtonRef.current.innerHTML = "Resume";
                }
            } else {
                //resume
                recorder.record();
                if (stoptime === true) {
                    stoptime = false;
                    timerCycle();
                }
                if (timerRef.current) {
                    timerRef.current.style.color = "#78CF4D";
                }
                if (pauseButtonRef.current) {
                    pauseButtonRef.current.innerHTML = "Pause";
                }
            }
        }
    };
 
    const stopRecording = () => {
 
        if (!recorder) {
            toast.error("Recorder not started! Please start recording first!");
        } else {
            //disable the stop button, enable the record too allow for new recordings
            if (stopButtonRef.current && recordButtonRef.current && pauseButtonRef.current) {
                stopButtonRef.current.disabled = true;
                recordButtonRef.current.disabled = false;
                pauseButtonRef.current.disabled = true;
            }
 
            //reset button just in case the recording is stopped while paused
            if (pauseButtonRef.current) {
                pauseButtonRef.current.innerHTML = "Pause";
            }
 
            recorder.stop(); //stop microphone access
 
            if (timerRef.current) {
                timerRef.current.style.color = "#EE6054";
            }
            if (stoptime === false) {
                stoptime = true;
            }
            gumStream.getAudioTracks()[0].stop();
 
            recorder.exportWAV(createDownloadLink);
 
            resetTimer();
        }
    };
 
    const timerCycle = () => {
        if (stoptime === false) {
            sec = parseInt(sec);
            min = parseInt(min);
            hr = parseInt(hr);
 
            sec = sec + 1;
 
            if (sec === 60) {
                min = min + 1;
                sec = 0;
            }
            if (min === 60) {
                hr = hr + 1;
                min = 0;
                sec = 0;
            }
 
            if (sec < 10 || sec === 0) {
                sec = "0" + sec;
            }
            if (min < 10 || min === 0) {
                min = "0" + min;
            }
            if (hr < 10 || hr === 0) {
                hr = "0" + hr;
            }
 
            if (timerRef.current) {
                timerRef.current.innerHTML = hr + ":" + min + ":" + sec;
            }
            setTimeout(() => {
                timerCycle();
            }, 1000);
        }
    };
 
    const resetTimer = () => {
        if (timerRef.current) {
            timerRef.current.innerHTML = "00:00:00";
        }
        stoptime = true;
        hr = 0;
        sec = 0;
        min = 0;
    };
 
    const createDownloadLink = async (blob) => {
        handleAudioBlobData(blob);
        setCustomBlob([...customBlob, blob]);
        var url = URL.createObjectURL(blob);
        setAudioUrl([...audioUrl, url]);
    };
 
    const deleteUrl = (url, indx) => {
        handleAudioBlobData(null);
        let auURL = audioUrl.filter((urls) => urls !== url);
        let deleteblob = customBlob.filter((blobs, index) => index !== indx);
        setCustomBlob(deleteblob);
        setAudioUrl(auURL);
    };
 
    return (
        <>
            <ToastContainer />
            <div className="d-flex flex-column align-start gap-2">
                <div className="mb-4 d-flex flex-column gap-2">
                    <h3 className="font-semibold">Record Sample Audio</h3>
                    <p>(Minimum-Duration: 10sec , Maximum-Duration: 25sec)</p>
                </div>
                <div className="timestamp">
                    <span id="stopwatch" className="font-large font-semibold" ref={timerRef}>00:00:00</span>
                </div>
                <div>
                    <ul className="recordingBtns flex-wrap">
                        <button
                            type="button"
                            className="btn btnGreen-small d-flex gap-2"
                            onClick={startRecording}
                            ref={recordButtonRef}
                        >
                            <img src={Start} alt="Start" />
                            Start
                        </button>
                        <button
                            type="button"
                            className="btn greyBtn d-flex gap-2"
                            onClick={pauseRecording}
                            ref={pauseButtonRef}
                        >
                            <img src={pausing} alt="pausing" />
                            Pause
                        </button>
                        <button
                            type="button"
                            className="btn btnRed-small d-flex gap-2"
                            onClick={stopRecording}
                            ref={stopButtonRef}
                        >
                            <img src={stopping} alt="stopping" />
                            Stop
                        </button>
 
                    </ul>
                </div>
            </div>
            {audioUrl.length > 0 && (
                <ul className="voiceVerifyAudio">
                    {audioUrl.map((url, index) => (
                        <li key={index}>
                            <div className="d-flex gap-2 align-center">
                                <audio src={url} controls controlsList="nodownload" capture="microphone"></audio>
                            </div>
                            <button type="button" className="audioDelete cursorPointer" onClick={(e) => deleteUrl(url, index)}>
                                <Delete />
                            </button>
                        </li>
                    ))}
                </ul>
 
            )}
        </>
    );
};
 
export default RegisterAudio;