import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
// import QcRejectFile from "./Pop-ups/qc-reject-file"

const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const QcInterRejectedUserList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

 
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [users, setUsersList] = useState([])
  const [roleBaseDetails, setRoleBaseDetails] = useState({});


  useEffect(() => {
    if(userLogin){
      setState(userLogin.user.state)
      setDistrict(userLogin.user.district)
    }

  }, []);
  

  useEffect(()=>{
    async function getUsers() {
      const url = `${process.env.REACT_APP_URL}/getinterrejectedspeaker`;
      const headers = reqHeader(true, true, true);
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)
          setUsersList(res.data.data)

       })
        .catch((err) => {});
    }
    getUsers();
  },[district])


  const rowClicked = (speakerID) => {
    //console.log("mobile, name, age, gender", mobile, name, age, gender);
    history.push({ pathname : "/qc-interrejectedfilelist",
    state: {speakerID:speakerID}
  })
  };



  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "speakerID",
        accessor: "speakerID",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          // console.log("row", row)
          return  <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
          onClick={() => rowClicked(row.original.speakerID)}>
          {row.original.speakerID}
        </span>
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      // {
      //   Header: "Total Duration",
      //   accessor: "recordedHours",
      //   sortType: "basic",
      //   filter: "text",
      // },
      // {
      //   Header: "Pending Files",
      //   accessor: "pendingCount",
      //   sortType: "basic",
      //   filter: "text",
      // }
   
    ],
    []
  );

  //console.log("coordinators",coordinators)


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Quality Checker
              </div>

              <UserProfile />
            </div>

            <div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                <div>
                    <label>State:</label>
                    <span className="value">{state}</span>
                  </div>
                  <div>
                    <label>District:</label>
                    <span className="value">{district}</span>
                  </div>
                  {/* <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div> */}
                  <div>
                    <label>Supervisor Name:</label>
                    <span className="value">{roleBaseDetails.Supervisor}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  {/* <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent mobileDashboard">
              <Table
                      columns={columns}
                      data={users}
                      count={users.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="users"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default QcInterRejectedUserList;