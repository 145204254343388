import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import PopupAddUser from "./Pop-ups/admin-add-user";
import AddManager from "./Pop-ups/admin-add-manager";
import AddSupervisorPopup from "./Pop-ups/admin-add-supervisor";
import AddTeamLeadPopup from "./Pop-ups/admin-add-teamlead";
import AddQCPopup from "./Pop-ups/admin-add-qc";
import AddIntraPopup from "./Pop-ups/admin-add-intra";
import AddInterPopup from "./Pop-ups/admin-add-inter";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";



const AllUsers = () => {

  const [users, setUsers] = useState([]);

  useEffect(() => {
    
    async function getallusers() {
      const url = `${process.env.REACT_APP_URL}/getallusers`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("RES", res.data.data);
          setUsers(res.data.data);
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getallusers();
  }, []);

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Role",
        accessor: "role",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "State",
        accessor: "state",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "District",
        accessor: "district",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Password",
        accessor: "accesscode",
        sortType: "basic",
        filter: "text",
      }
    ],
    []
  );

return(
  <>
  <div className="container entitiCustomerWrap d-flex">
    <LeftNavigation />

    <div className="pageRight">
      <div className="userSection">
        <div className="welcomeUser">
          Admin
        </div>

        <UserProfile />
      </div>

      <div >
        <div className="d-flex justify-between regionSelectWrap">
          <div className="regionselect d-flex">
            <div className="inputWrap" >
              <button className="btn adminUserBtn"  onClick={async (e) => {
              const result = await CustomDialog(
                <PopupAddUser/>,
                {
                  title: "Add User",
                  showCloseIcon: true,
                }
              );
            }}>Add Admin/Coordinator/Customer</button>
            </div>
            <div className="inputWrap" style={{paddingLeft:"10px"}}>
              <button className="btn adminUserBtn"  onClick={async (e) => {
              const result = await CustomDialog(
                <AddManager/>,
                {
                  title: "Add Manager",
                  showCloseIcon: true,
                }
              );
            }}>Add Manager</button>
            </div>
            <div className="inputWrap" style={{paddingLeft:"10px"}}>
              <button className="btn adminUserBtn"  onClick={async (e) => {
              const result = await CustomDialog(
                <AddTeamLeadPopup/>,
                {
                  title: "Add Team Lead",
                  showCloseIcon: true,
                }
              );
            }}>Add Team Lead</button>
            </div>
            <div className="inputWrap" style={{paddingLeft:"10px"}}>
                  <button className="btn adminUserBtn"  onClick={async (e) => {
                  const result = await CustomDialog(
                    <AddSupervisorPopup/>,
                    {
                      title: "Add Supervisor",
                      showCloseIcon: true,
                    }
                  );
                }}>Add Supervisor</button>
            </div>
            <div className="inputWrap" style={{paddingLeft:"10px"}}>
                  <button className="btn adminUserBtn"  onClick={async (e) => {
                  const result = await CustomDialog(
                    <AddQCPopup/>,
                    {
                      title: "Add QC",
                      showCloseIcon: true,
                    }
                  );
                }}>Add QC</button>
            </div>
            <div className="inputWrap" style={{paddingLeft:"10px"}}>
                  <button className="btn adminUserBtn"  onClick={async (e) => {
                  const result = await CustomDialog(
                    <AddIntraPopup/>,
                    {
                      title: "Add Intra",
                      showCloseIcon: true,
                    }
                  );
                }}>Add Intra</button>
            </div>
            <div className="inputWrap" style={{paddingLeft:"10px"}}>
                  <button className="btn adminUserBtn"  onClick={async (e) => {
                  const result = await CustomDialog(
                    <AddInterPopup/>,
                    {
                      title: "Add Inter",
                      showCloseIcon: true,
                    }
                  );
                }}>Add Inter</button>
            </div>
          </div>
          
          
        </div>

        <div className="d-flex justify-between">

        </div>
      </div>
      
      <div className="projectCoordinatorContent">
        <div className="projectContent">
              <Table
                columns={columns}
                data={users}
                count={users.length}
                pagination={true}
                expanded={false}
                isHeader={true}
                filter={true}
                isCountDisplayed={true}
                customText="All Users"
              />
        </div>
      </div>
    </div>
  </div>
</>
)
}

export default AllUsers;