import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from '../assets/images/Logo.svg';
import { PasswordHide, PasswordShow } from "./icons.component";
import { useForm } from "react-hook-form";
import { login, clearMessage } from "../actions/userActions.js";
import { Link, Redirect, useHistory } from "react-router-dom";
//import { login, clearMessage } from "../actions/userActions.js";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const schema = yup.object().shape({
  mobile: yup
    .string() 
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('A mobile number is required'),
  accesscode: yup
    .string()
    .required("Access code is required !!!")
});

const Login = () => {

  const { register, handleSubmit, watch, errors, clearErrors } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const userLogin = useSelector((state) => state.userLogin);
 
  const dispatch = useDispatch();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    if(userLogin.error){
     toast.error(userLogin.error);
     setTimeout(()=>{
      window.location.reload()
     },3000)
   
     //dispatch(clearMessage(userLogin))
    }
  }, [userLogin.error])

  //console.log("userLogin..",userLogin);

  const submitHandler = (data) => {
    console.log("Login Data in Submit Handler is ", data);
    // data.isManager = isManager;
     dispatch(login(data));
  };


  if(userLogin.success==true && userLogin.token && userLogin.user.role == "Vendor"){
    return <Redirect to="/audiorecordview" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Coordinator"){
    return <Redirect to="/coordinator-userlist" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Admin"){
    return <Redirect to="/admin-filelist" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Supervisor"){
    return <Redirect to="/supervisor-userlist" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Manager"){
    return <Redirect to="/manager-filelist" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "TeamLead"){
    return <Redirect to="/teamlead-filelist" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "QualityChecker"){
    return <Redirect to="/qc-userlist" />
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Customer"){	
    return <Redirect to="/customer-filelist" />	
  }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "QCPR"){	
    return <Redirect to="/qcpr-seg-completed-userlist" />	
  }
  // else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Intra1"){	
  //   return <Redirect to="/intra-userlist" />	
  // }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Intra2"){	
  //   return <Redirect to="/intra2-userlist" />	
  // }
  // else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Inter1"){	
  //   return <Redirect to="/inter-usersamplefilelist" />	
  // }else if(userLogin.success==true && userLogin.token && userLogin.user.role == "Inter2"){	
  //   return <Redirect to="/inter2-usersamplefilelist" />	
  // }


  return (
      <>
        <div className="container RegisterWrap d-flex">
          <ToastContainer />
          <div className="registerLHS">
            <img src={Logo} alt="Logo" className="logo" />
            <div className="loginFooter">
              Copyright © Megdap Innovation Labs | <a href="#">Contact Us</a>
            </div>
          </div>
          <div className="registerRHS">
          <div className="registrationForm loginForm">
              <h2 className="loginTitle">Login to your Account</h2>
              <form className="form" onSubmit={handleSubmit(submitHandler)}>
                <div className="registerFormWrap">

                <div
                  className={`inputWrap emailField ${
                    errors.mobile ? "error" : ""
                  }`}>
                  <input
                      ref={register}
                      type="number"
                      placeholder="Mobile Number"
                      name="mobile" 
                  />

                  {errors.mobile && (
                    <span className="inputErrorMsg">
                      {errors.mobile?.message}
                    </span>
                  )}
                  </div>

                  <div
                    className={`inputWrap passwordField ${
                      errors.accesscode ? "error" : ""
                    }`}>
                  <input
                      ref={register}
                      type={passwordShown ? "text" : "password"}
                      placeholder="Password"
                      name="accesscode"
                    />
                    <div className="passwordEye">
                      <i onClick={togglePasswordVisiblity}>
                        {passwordShown ? <PasswordShow /> : <PasswordHide />}
                      </i>{" "}
                    </div>
                    {errors.accesscode && (
                    <span className="inputErrorMsg">
                      {errors.accesscode?.message}
                    </span>
                    )}
                  </div>

                  {/* <div className="remember-forgotPass d-flex">
                    <div className="rememberMeWrap">
                      <input type="checkbox" id="rememberMe" />
                      <label htmlFor="rememberMe">Remember me</label>
                    </div>

                    <div className="ForgotCode">Forgot Access Code</div>
                  </div> */}

                  <div className="formFooter">
                    <button className="roundedBtn-Blue">log in</button>
                    <div className="orTxt">Or</div>
                    {/* <button className="roundedBtn-White" onClick={signUp}>Sign up</button> */}
                    <div className="d-flex flex-column gap-2">
                    <Link className="loginLink" to="/register">
                      <button className="roundedBtn-White">
                        SIGN UP
                      </button>
                    </Link>
                    <Link className="loginLink" to="/verifyvoice">
                      <button className="roundedBtn-White">
                        VERIFY VOICE
                      </button>
                    </Link>
                  </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
  );
 
};

export default Login;