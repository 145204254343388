import React , { useEffect, useState } from "react";
import { PasswordHide } from "../icons.component";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../../helper/reqHeader.js";
import axios from "axios";

// const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const phoneRegExp = /[1-9]{1}[0-9]{9}/;

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^([^0-9]*)$/, "Name should not contain numbers!")
    .required("Name is required!"),
  mobile: yup
    .string() 
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('A mobile number is required'),
  email: yup
    .string()
    .required("Email is required!")
    .email("Enter a valid Email ID!"),
  state: yup
  .string()
  .required("State is required!"),
  district: yup
  .string()
  .required("district is required!"),
   
});


const AddQcPopup = () => {

  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onSubmit",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [language, setLanguage] = useState([]);


  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  

  useEffect(() => {
    async function getDistricts() {
    const url = `${process.env.REACT_APP_URL}/getdistricts`;
    const headers = reqHeader(true, true, true);
    console.log("header", headers)
    const params={
      state:state
    };
    const config = { headers, params };
    await axios
      .get(url, config)
      .then((res) => {
        console.log("Districts", res.data.data)
        setDistricts(
          res.data.data.map((district) => ({ label: district, value: district }))
        );
        //console.log("From useEffect languages");
      })
      .catch((err) => {});
  }
  getDistricts();
}, [state]);


  const onChangeState = (e) => {
    // console.log("state", e.target.value)
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    // console.log("district", e.target.value)
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }


  const submitHandler = async(data) => 
  {
         console.log("data", data);

         const url = `${process.env.REACT_APP_URL}/addnewuser`;
         console.log("url..",url);
         const headers = reqHeader(true, true, true);
  
         const config = {
             headers,
          };
 
         const body = {
          name:data.name,
          mobile:data.mobile,
          state:data.state,
          district:data.district,
          role:"QualityChecker",
          //supervisorDistricts:supervisorDistrict,
          email:data.email
         };
         console.log("body", body)
         try {
           const response = await axios.post(url, body, config);
           const result = await response.data;
          //  console.log("The user created is" + JSON.stringify(result));
           toast.success("QC Added Successfully !!")
           setTimeout(()=>{
            window.location.reload();
           }, 3000)
          
          

         } catch (error) {
           //console.log(error.response.data.error);
           toast.error(error.response.data.error);
           //toast.error("Incorrect Details");
         }

     };

  return (
      <div className="addNewUserPop" style={{margin:"10px"}}>
          <ToastContainer />
        <br/>
        <form className="form" onSubmit={handleSubmit(submitHandler)}>
          <div className="popupForm">
            <div className="formRow child-2" >
              <div className="inputBlock">
                <label>Name<span className="requiredField">*</span></label>
                <div className={`inputWrap ${errors.name ? "error" : ""}`}>
                  <input
                        type="text"
                        placeholder=" Full Name"
                        name="name"
                        ref={register}
                      />
                      {errors.name && (
                        <span className="inputErrorMsg">
                          {errors.name?.message}
                        </span>
                      )}
                 </div>
              </div>
              <div className="inputBlock">
                <label>Mobile Number</label>
                <div className={`inputWrap ${errors.mobile ? "error" : ""}`}>
                     <input
                        type="text"
                        placeholder="Mobile No"
                        name="mobile"
                        ref={register}
                      />
                      {errors.mobile && (
                        <span className="inputErrorMsg">
                          {errors.mobile?.message}
                        </span>
                      )}
                  </div>
              </div>
              {/* <div className="inputBlock">
                <label>State<span className="requiredField">*</span></label>
                <div className="inputWrap">
                  <input type="text" placeholder="Enter State" />
                </div>
              </div> */}

            </div>
            <div className="formRow child-2" style={{marginTop:"10px"}}>
              <div className="inputBlock">
                <label>Email<span className="requiredField">*</span></label>
                <div className={`inputWrap ${errors.email ? "error" : ""}`}>
                  <input type="text" 
                    placeholder="Enter Email"
                    name="email"
                    ref={register}
                   />
                  {errors.email && (
                        <span className="inputErrorMsg">
                          {errors.email?.message}
                        </span>
                      )}
                </div>
              </div>
            </div>

                <div className="formRow child-2" style={{marginTop:"10px"}}>
                <div className="inputBlock">
                  <label>State<span className="requiredField">*</span></label>
                    <div className = {`inputWrap ${
                              errors.state ? "error" : ""
                          }`}>
                          <select
                              {...register("state")}
                              // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                           
                              onChange={(e) => onChangeState(e)}
                            >
                              {}
                              <option value="">Please select State</option>
                              {states && states.map((state, index)=>{
                                return <option key={index} value={state.value}>{state.label}</option>
                              })}
                            </select>
                            {errors.state && (
                            <span className="inputErrorMsg">
                              {errors.state?.message}
                            </span>
                          )}
                    </div>
                  </div>

                  <div className="inputBlock">
                    <label>District<span className="requiredField">*</span></label>
                    <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                          <select className="error"
                              {...register("district")}
                              // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                              onChange={(e) => OnChangeDistrict(e)}
                            >
                              <option value="">Please select District</option>
                              {districts && districts.map((district, index)=>{
                                console.log("district", district)
                                return <option  key={index} value={district.value}>{district.label}</option>
                              })}
                            </select>
                            {errors.district && (
                            <span className="inputErrorMsg">
                              {errors.district?.message}
                            </span>
                          )}
                        </div>
                  </div>

          
                </div>

           
          </div>

          <div className="footerAction d-flex justify-center" style={{margin:"20px"}}>
            <button className="adminUserBtn btn">Submit</button>
          </div>
        </form>
      </div>
    );
};

export default AddQcPopup;



// class AddUserPopup extends React.Component {
//   render() {
//     return (
//       <div className="addNewUserPop">

//         <h2 className="popupTitle">Add New User</h2>

//         <div className="popupForm">
//           <div className="formRow child-2">
//             <div className="inputBlock">
//               <label>First Name<span className="requiredField">*</span></label>
//               <div className="inputWrap">
//                 <input type="text" placeholder="Enter First Name" />
//               </div>
//             </div>

//             <div className="inputBlock">
//               <label>State<span className="requiredField">*</span></label>
//               <div className="inputWrap">
//                 <input type="text" placeholder="Enter State" />
//               </div>
//             </div>
//           </div>

//           <div className="formRow child-2">
//             <div className="inputBlock">
//               <label>Email<span className="requiredField">*</span></label>
//               <div className="inputWrap">
//                 <input type="text" placeholder="Email" />
//               </div>
//             </div>

//             <div className="inputBlock">
//               <label>District<span className="requiredField">*</span></label>
//               <div className="inputWrap">
//                 <input type="text" placeholder="Enter District" />
//               </div>
//             </div>
//           </div>

//           <div className="formRow child-2">
//             <div className="inputBlock">
//               <label>Mobile Number</label>
//               <div className="inputWrap mobileField">
//                 <select>
//                   <option>+91</option>
//                   <option>+91</option>
//                   <option>+91</option>
//                 </select>
//                 <input type="text" placeholder="Mobile Number" />
//               </div>
//             </div>

//             <div className="inputBlock">
//               <label>Select Role<span className="requiredField">*</span></label>
//               <div className="selectWrap">
//                 <span className="twoWayArrow"></span>
//                 <select>
//                   <option>Admin</option>
//                   <option>Co-Ordinator</option>
//                 </select>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="footerAction d-flex justify-center">
//           <button className="orangeBtn btn">Submit</button>
//         </div>
//       </div>
//     );
//   }
// };

// export default AddUserPopup;