import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Select from "react-select";
import { format } from "date-fns";
// import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import { useDispatch, useSelector } from "react-redux";
import Excel from "../assets/images/excel.png"

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
  supervisor: yup
    .string()
    .required("Supervisor is required!"),
});

const TeamLeadFileList = () => {

  const userLogin = useSelector((state) => state.userLogin);
  const history = useHistory();

  //console.log("userLogin", userLogin)
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [supervisor, setSupervisor] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({})

  useEffect(() => {
    const sorted = userLogin.user.teamleadDistricts.sort((a, b) => a.state.localeCompare(b.state))
    
    const unique = [...new Map(sorted.map((m) => [m.state, m])).values()];

    setStates(unique.map((state)=>({ label: state.state, value: state.state })));

  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Districts", res.data.data)
          let distArray = []
          userLogin.user.teamleadDistricts.map((row) => {
            if(row.state == state){
              distArray.push({label: row.district, value: row.district})
            }
          
          }) 
          //console.log("distArray", distArray)
          setDistricts(
            distArray
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();
  }, [state]);

  useEffect(() => {
    
    async function getSupervisors() {
      const url = `${process.env.REACT_APP_URL}/getteamleadsupervisors`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Supervisors", res.data.data);
          setSupervisors(
            res.data.data.map(( supervisor ) => ({ label: supervisor.name, value: supervisor._id }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getSupervisors();
  }, [district]);

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getsupervisorcoordinators`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state:state,
        district:district,
        supervisorID:supervisor 
       };
      //console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();
  }, [supervisor]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);

    // setDistricts(
    //   userLogin.user.teamleadDistricts.map((row) => ({ label: row.district, value: row.district }))
    // );
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }

  const onChangeSupervisor = (e) => {
    console.log("e", e.target.value)
    setValue('supervisor', e.target.value,  { shouldValidate: true })
    setSupervisor(e.target.value);
  }
  
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    async function getallFiles() {
      const url = `${process.env.REACT_APP_URL}/getallfiles`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      //console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          setFileList(res.data.data)
          const times = [];
          let i = 0;
          res.data.data.map((filedata, index)=>{
            //console.log("filedata", filedata.status)
            if(filedata.status == "Accepted"){
              console.log("filedata status", filedata.status, i++);
              times.push(filedata.fileDuration);
            }
            })
            const sum = sumTime(times);
            console.log("sum", sum)
            setTotalDuration(sum);
        })
          .catch((err) => {});
    }
    getallFiles();

    async function getrolebasedetails() {
      //console.log("Inside getrolebase")
      const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
      const headers = reqHeader(true, true, true);
      const params = {
        state, district, coordinator: e.target.value
      }
      //console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)
          setRoleBaseDetails({"Supervisor":res.data.data.supervisor[0].supervisorName, "TeamLead":res.data.data.teamlead[0].teamleadName  })
          // setFileList(res.data.data)
          // const times = [];
          // res.data.data.map((filedata)=>{
          //   if(filedata.status == "Accepted"){
          //     times.push(filedata.fileDuration)
          //   }
          //   })
          //   const sum = sumTime(times)
          //   setTotalDuration(sum)
        })
          .catch((err) => {});
    }
    getrolebasedetails();

  }

  const sumTime = (times) => {
    console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    // return new Date(sumSeconds * 1000).toISOString().substr(11, 8);

    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

  let columns = useMemo(
    () => [
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls></audio>
            </div>
          );
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({value, column, row}) => {
          return(
            row.original.status == "Accepted" && row.original.isQcAccepted == false ? "QCPending" : row.original.status
          )
        }
      },
      {
        Header: "Reject reason",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({value, column, row}) => {
          return(
            row.original.status == "Accepted" && row.original.isQcAccepted ? "" : row.original.status == "QcRejected" ? row.original.qcRejectionReason : row.original.status == "SupervisorRejected" ? row.original.supervisorRejectionReason :   row.original.status == "CoordinatorRejected" ? row.original.coordinatorRejectionReason : ""
          )
        }
      },
      // {
      //   Header: "Paid",
      //   accessor: "isPaid",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div>
      //         {row.original.isPaid ? "Yes" : "No"}
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  // const headers = [
  //   { label: "Image name", key: "imageName" },
  //   { label: "File name", key: "fileName" },
  //   { label: "Duration", key:"fileDuration" },
  //   { label: "Vendor name", key: "vendorName" },
  //   { label: "Recorded On", key:"recordedOn" },
  //   { label: "State", key: "state"},
  //   { label: "District", key: "district"},
  //   { label: "Coordinator", key: "coordinatorName"},
  //   { label: "Status", key: "status" },
  //   { label: "QC reject reason", key: "qcRejectionReason" },
  //   { label: "Coordiantor reject reason", key: "coordinatorRejectionReason" },
  //   { label: "Supervisor reject reason", key: "supervisorRejectionReason" },
  //   // { label: "Paid", key: "isPaid"},
  // ];


    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Team Lead
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            // console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.supervisor ? "error" : ""
                      }`}>
                      <select
                          {...register("supervisor")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeSupervisor(e)}
                        >
                          {}
                           <option value="">Supervisors</option>
                          {supervisors && supervisors.map((supervisor)=>{
                            //console.log("Option supervisor", supervisor.value)
                            return <option key={supervisor.value} value={supervisor.value}>{supervisor.label}</option>
                          })}
                        </select>
                        {errors.supervisor && (
                        <span className="inputErrorMsg">
                          {errors.supervisor?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value="">Co-ordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            //console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                  <div>
                    <label>Supervisor Name:</label>
                    <span className="value">{roleBaseDetails.Supervisor}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                 
                  
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>
                {/* <CSVLink data={fileList} filename={state + "_" + district + "_" + coordinator} headers={headers} className="btn btnExcelGreen" target="_blank">
                  <img src={Excel} alt="Excel"/> 
                  Export FileList
                </CSVLink> */}
                {/* <button className="btn blueBtn-fill">Excel Export</button> */}
              </div>
            </div>
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default TeamLeadFileList;