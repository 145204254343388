import React , { useEffect, useState, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
// import QcRejectFile from "./Pop-ups/qc-reject-file"

const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = useRef();
    const resolvedRef = ref || defaultRef;

    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const InterUserSampleFileList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

 
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [userSamples, setUserSamples] = useState([])
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [selectedRowsValues, setSelectedRowsValues] = useState({});
  const [isUnique, setIsUnique] = useState(false);
  const [isDuplicate, setIsDuplicate] = useState(false);


  useEffect(() => {
    if(userLogin){
      let stateArray = [{label: userLogin.user.state, value: userLogin.user.state}]
      setStates(stateArray);
      setState(userLogin.user.state);

      const sorted = userLogin.user.InterDistricts.sort((a, b) => a.district.localeCompare(b.district))
      setDistricts(
        sorted.map(( district ) => ({ label: district.district, value: district.district }))
      )
    }

  }, []);  

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getcoordinators`;
      const headers = reqHeader(true, true, true);
      // console.log("state, district", state, district)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          // console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();
    
  }, [district]); 


  // const onChangeState = (e) => {
  //   setValue('state', e.target.value,  { shouldValidate: true })
  //   setState(e.target.value);
  // }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }
   
  const onChangeCoordinator = (e) => {
    setUserSamples([]);
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getinterusersampleaudio`;
        const headers = reqHeader(true, true, true);
        const params = {state, district, coordinator:e.target.value}
        const config = { params, headers };
        //console.log("params", params)
        await axios
          .get(url, config)
          .then((res) => {
            //console.log("res", res.data.data)
            setUserSamples(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();

    async function getrolebasedetails() {
      const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("supervisor role", res.data.data)
          setRoleBaseDetails({"Supervisor":res.data.data.supervisor.length == 0 ? "" : res.data.data.supervisor[0].supervisorName, "TeamLead":res.data.data.teamlead.length == 0 ? "" : res.data.data.teamlead[0].teamleadName, "Manager":  res.data.data.manager.length == 0 ? "" : res.data.data.manager[0].name })
        })
          .catch((err) => {});
    }
    getrolebasedetails();

  }

  useEffect(()=>{
    if(selectedRowsValues.length > 0){
      let rows = selectedRowsValues.map((value, index) => {
        return value.original;
      });

      // let inter1uniqueStage = ""
      // let inter1Duplicate = ""
      let selectedStages = rows.map((value) => {
          if(value.inter1CheckStatus == "Unique"){
            return value.inter1CheckStatus
          }else if(value.inter1CheckStatus == "Duplicate"){
            return value.inter1CheckStatus
          }else if(value.intra2CheckStatus == "Accepted"){
            return value.intra2CheckStatus
          }
      });

      //console.log("selectedStages", selectedStages);

      let uniqueStage = [...new Set(selectedStages)];
      //console.log("uniqueStage..",uniqueStage);

    
      if(uniqueStage.length == 1){
        if(uniqueStage.toString() === "Unique"){
            setIsUnique(false);
            setIsDuplicate(false);
        }else if(uniqueStage.toString() === "Duplicate"){
            setIsDuplicate(false);
            setIsUnique(false);
        }else if(uniqueStage.toString() === "Accepted"){
          setIsUnique(true);
          setIsDuplicate(true);
      }else if(uniqueStage.toString() === "undefined" ){
          setIsUnique(false);
          setIsDuplicate(false);
        }else{
          setIsUnique(false);
          setIsDuplicate(false);
        }
      }else{
        //console.log("outer else")
        setIsUnique(false);
        setIsDuplicate(false);
      }
    }

  },[selectedRowsValues])

  const handleFileDuplicateBatch = async () => {
    let counter = 0;
    //let interduplicatespeaker = "";
    if(selectedRowsValues.length > 0){
      //interduplicatespeaker = selectedRowsValues.map((value, index)=>value.original.speakerID).join("+")

      //console.log("interduplicatespeaker", interduplicatespeaker)

      await Promise.all(
        selectedRowsValues.map(async(value, index) => {
          const headers = reqHeader(true, true, true);
          //console.log("value", value)

          let body={
            userID: value.original.userID,
            role: "Inter1",
            inter1CheckStatus: "Duplicate",
            //inter1DuplicateSpeaker: interduplicatespeaker
          }

          //console.log("body", body)
          const config = { headers };
          let url = `${process.env.REACT_APP_URL}/interuniqueduplicatefile`;
      
            await axios
            .put(url,body,config)
            .then((res) => {
              console.log("res", res)
              counter = counter + 1;
            })
            .catch((err) => {
              console.log("err", err)
              //toast.error("Speaker not updated. Please update speaker")
            });

    
            console.log("selectedRowsValues.length, counter", selectedRowsValues.length, counter)
            if (selectedRowsValues.length === counter) {
              toast.success("Files are updated successfully!");
              window.location.reload();
            }
           
        })
      )
    }
  };
  
  const handleFileUniqueBatch = async () => {
    let counter = 0;
    if(selectedRowsValues.length > 0){
      await Promise.all(
        selectedRowsValues.map(async(value, index) => {
          const headers = reqHeader(true, true, true);
          //console.log("value", value)

          let body={
            userID: value.original.userID,
            role: "Inter1",
            inter1CheckStatus: "Unique"
          }

          console.log("body", body)
          const config = { headers };
          let url = `${process.env.REACT_APP_URL}/interuniqueduplicatefile`;
      
            await axios
            .put(url,body,config)
            .then((res) => {
              console.log("res", res)
              counter = counter + 1;
            })
            .catch((err) => {
              console.log("err", err)
              //toast.error("Speaker not updated. Please update speaker")
            });

    
            console.log("selectedRowsValues.length, counter", selectedRowsValues.length, counter)
            if (selectedRowsValues.length === counter) {
              toast.success("Files are updated successfully!");
              window.location.reload();
            }
           
        })
      )
    }
  };

  let multiSelect = true;
  const checkBoxCols = multiSelect
  ? {
      id: "selection",
      // The header can use the table's getToggleAllRowsSelectedProps method
      // to render a checkbox
      Header: ({ getToggleAllPageRowsSelectedProps }) => (
        <div>
          <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
        </div>
      ),
      // The cell can use the individual row's getToggleRowSelectedProps method
      // to the render a checkbox
      Cell: ({ row }) => (
        <div>
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
        </div>
      ),
    }
  : {
      id: "_id",
      Header: "Id",
      accessor: "_id",
      sortType: "basic",
      filter: "text",
    };

  let columns = useMemo(
    () => [
      checkBoxCols,
      {
        Header: "speakerID",
        accessor: "speakerID",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "latitude",
        accessor: "latitude",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return(<div>{Number(row.original.latitude).toFixed(2)}</div>
          )
        },
      },
      {
        Header: "longitude",
        accessor: "longitude",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return(<div>{Number(row.original.longitude).toFixed(2)}</div>
          )
        },
      },
      {
        Header: "file name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      {
        Header: "Total Duration",
        accessor: "recordedHours",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Status",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.inter1CheckStatus ? row.original.inter1CheckStatus : row.original.intra2CheckStatus == "Accepted" ? "Intra2 Accepted" : row.original.intra2CheckStatus}
            </div>
          );
        },
      },
   
    ],
    []
  );


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Inter1 Check
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
              <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          //onChange={(e) => onChangeState(e)}
                        >
                          {}
                           {/* <option value="">Select State</option> */}
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            //console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value=""> Co-ordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            // console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                <div>
                    <label>State:</label>
                    <span className="value">{state}</span>
                  </div>
                  <div>
                    <label>District:</label>
                    <span className="value">{district}</span>
                  </div>
                  {/* <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div> */}
                  <div>
                    <label>Supervisor Name:</label>
                    <span className="value">{roleBaseDetails.Supervisor}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  {/* <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="fileListTable">
              <div className="tableTopHeader" style={{display:"inline-flex"}}>
              <button className="btn blueBtn-fill" style={{marginLeft:"10px"}} disabled={!isUnique}  onClick={handleFileUniqueBatch} >Unique User</button>
              <button className="btn blueBtn-fill" style={{marginLeft:"10px"}} disabled={!isDuplicate} onClick={handleFileDuplicateBatch} >Duplicate User</button>
              </div>
              
            </div>
            <div className="projectCoordinatorContent">
              <div className="projectContent mobileDashboard">
              <Table
                      columns={columns}
                      data={userSamples}
                      count={userSamples.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isMultiSelect={multiSelect}
                      setSelectedRowsValues = {setSelectedRowsValues}
                      isCountDisplayed={true}
                      customText="users"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default InterUserSampleFileList;