import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Select from "react-select";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import Excel from "../assets/images/excel.png"

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const AdminFileList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);
console.log("userLogin", userLogin)

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [stateFileList, setStateFileList] = useState([])


  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("Districts", res.data.data)
          setDistricts(
            res.data.data.map((district) => ({ label: district, value: district }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();

    async function getStateFileList(){
      const url = `${process.env.REACT_APP_URL}/getstatefilelist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("res.data.data", res.data.data.length)
          setStateFileList(res.data.data)
        })
        .catch((err) => {});
    }

    getStateFileList()
  }, [state]);

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getcoordinators`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator.name, value: coordinator.name }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();

  }, [district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }
  
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    async function getallFiles() {
      const url = `${process.env.REACT_APP_URL}/getallfiles`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("res.data.data", res.data.data.length)
          setFileList(res.data.data)
          const times = [];
          res.data.data.map((filedata)=>{
            if(filedata.status == "Accepted"){
              times.push(filedata.fileDuration)
            }
            })
            const sum = sumTime(times)
            setTotalDuration(sum)
        })
          .catch((err) => {});
    }
    getallFiles();

    async function getrolebasedetails() {
      const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("supervisor role", res.data.data)
          setRoleBaseDetails({"Supervisor":res.data.data.supervisor.length == 0 ? "" : res.data.data.supervisor[0].supervisorName, "TeamLead":res.data.data.teamlead.length == 0 ? "" : res.data.data.teamlead[0].teamleadName, "Manager":  res.data.data.manager.length == 0 ? "" : res.data.data.manager[0].name })
        })
          .catch((err) => {});
    }
    getrolebasedetails();

  }

  const sumTime = (times) => {
    //console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);

    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }


  let columns = useMemo(
    () => [
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls></audio>
            </div>
          );
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.status == "Accepted" && row.original.isQcAccepted ? "Accepted" : row.original.status == "Accepted" && !row.original.isQcAccepted ? "QCPending" : row.original.status}
            </div>
          );
        },
      },
      // {
      //   Header: "Rate",
      //   accessor: "rate",
      //   sortType: "basic",
      //   filter: "text",
      // },
      // {
      //   Header: "Paid",
      //   accessor: "isPaid",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div>
      //         {row.original.isPaid ? "Yes" : "No"}
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  const headers = [
    { label: "Image name", key: "imageName" },
    { label: "File name", key: "fileName" },
    { label: "Duration", key:"fileDuration" },
    { label: "Speaker name", key: "vendorName" },
    { label: "Recorded On", key:"recordedOn" },
    { label: "Coordinator", key: "coordinatorName"},
    { label: "State", key: "state"},
    { label: "District", key: "district"},
    { label: "Pincode", key: "pincode"},
    { label: "Age", key: "age" },
    { label: "Gender", key: "gender"},
    { label: "Language", key: "language" },
    { label: "Education", key: "qualification" },
    { label: "Staying Years", key: "stayingyears" },
    { label: "Socio-economic Status", key: "socioeconomicstatus" },
    { label: "Speaker ID", key: "speakerID"},
    { label: "Status", key: "status" },
    { label: "QCAccepted", key: "isQcAccepted"},
    { label: "QC Accepted By", key: "acceptedByQcName"},
    { label: "QC Rejected By", key: "rejectedByQcName"},
    { label: "Phone Brand", key: "phonebrand"},
    { label: "Known Languages", key: "knownlanguages"}
  ];


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Admin
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Select District</option>
                          {districts && districts.map((district)=>{
                            //console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value=""> Co-ordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails d-flex">
                  <div className="firstDiv">
                  <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  </div>
                  <div style={{paddingLeft: "18px"}}>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>
                <CSVLink data={fileList} filename={state + "_" + district + "_" + coordinator} headers={headers} className="btn btnExcelGreen" target="_blank">
                  <img src={Excel} alt="Excel"/> Export FileList
                </CSVLink>
                <CSVLink data={stateFileList} filename={state} headers={headers} className="btn btnExcelGreen" target="_blank">
                  <img src={Excel} alt="Excel"/> Export StateFiles
                </CSVLink>
                {/* <button className="btn blueBtn-fill">Excel Export</button> */}
              </div>
            </div>
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AdminFileList;