import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Select from "react-select";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  // coordinator: yup
  //   .string()
  //   .required("Coordinator is required!"),
});

const CustomerFileList = () => {

  const history = useHistory();

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  // const [coordinators, setCoordinators] = useState([]);
  // const [coordinator, setCoordinator] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  // const [roleBaseDetails, setRoleBaseDetails] = useState({});


  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Districts", res.data.data)
          setDistricts(
            res.data.data.map((district) => ({ label: district, value: district }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();
  }, [state]);

  useEffect(() => {

    async function getallFiles() {
      const url = `${process.env.REACT_APP_URL}/getdistrictwisefiles`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("res.data.data", res.data.data)
          setFileList(res.data.data)
          const times = [];
          res.data.data.map((filedata)=>{
              times.push(filedata.fileDuration)
            })
            const sum = sumTime(times)
            setTotalDuration(sum)
        })
          .catch((err) => {});
    }
    getallFiles();

  }, [district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);

  }
  
  // const onChangeCoordinator = (e) => {
  //   setValue('coordinator', e.target.value,  { shouldValidate: true })
  //   setCoordinator(e.target.value);

  // }

  const sumTime = (times) => {
    //console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }


  let columns = useMemo(
    () => [
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Audio",
        accessor: "silenceRemovedLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.silenceRemovedLocation} controls></audio>
            </div>
          );
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
    ],
    []
  );

  const headers = [
    { label: "Image name", key: "imageName" },
    { label: "File name", key: "fileName" },
    { label: "Duration", key:"fileDuration" },
    { label: "State", key: "state"},
    { label: "District", key: "district"},
  ];


    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Customer
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Please select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Please select District</option>
                          {districts && districts.map((district)=>{
                            console.log("district", district)
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                    {/* <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value="">Please select Coordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div> */}
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                  {/* <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div> */}
                  
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>
                <CSVLink data={fileList} filename={state + "_" + district} headers={headers} className="btn blueBtn-fill" target="_blank">
                  Export
                </CSVLink>
                {/* <button className="btn blueBtn-fill">Excel Export</button> */}
              </div>
            </div>
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default CustomerFileList;