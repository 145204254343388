import { SearchIcon } from "../icons.component";

const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  customText,
}) => {
  return (
    <div className="projectSearch search">
      <input
        type="text"
        placeholder={`Search ${customText}`}
        value={globalFilter || ""}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
      />
      <button className="searchBtn">
        <SearchIcon />
      </button>
    </div>
  );
};

export default GlobalFilter;
