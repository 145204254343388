import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import { format } from "date-fns";
// import { CSVLink } from "react-csv";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
//import CoordinatorRejectFile from "./Pop-ups/coordinator-reject-file";
//import { Alert, CustomDialog, Confirm } from "react-st-modal";
import Excel from "../assets/images/excel.png"
import Omni from "../assets/images/omni.png"
import Mic from "../assets/images/mic.png"
import Logo from '../assets/images/Logo.svg';
import {BiMenuAltRight} from "react-icons/bi"
import { useForm, Controller } from "react-hook-form";
// import { yupResolver } from "@hookform/resolvers/yup";
// import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CoordinatorFileList = () => {

  const userLogin = useSelector((state) => state.userLogin);

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    //resolver: yupResolver(schema)
  });

  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  //const [imgSrc, setImgSrc] = useState([]);

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
    // disable right click
    document.addEventListener('contextmenu', event => event.preventDefault());
 
    document.onkeydown = function (e) {
 
        // disable F12 key
        if(e.keyCode == 123) {
            return false;
        }
 
        // disable I key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
            return false;
        }
 
        // disable J key
        if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            return false;
        }
 
        // disable U key
        if(e.ctrlKey && e.keyCode == 85) {
            return false;
        }
    }
  },[])

  useEffect(()=>{
    async function getallFiles() {
      const url = `${process.env.REACT_APP_URL}/getallfiles`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state:userLogin.user.state, 
        district:userLogin.user.district, 
        coordinator:userLogin.user.name
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)
          const boolsorted = res.data.data.sort((a,b) => a.isQcAccepted - b.isQcAccepted);
          let sorted=boolsorted.sort((a, b) => a.status.localeCompare(b.status));
          setFileList(sorted)
          const times = [];
          res.data.data.map((filedata)=>{
            if(filedata.status == "Accepted"){
              times.push(filedata.fileDuration)
            }
            })
            const sum = sumTime(times)
            setTotalDuration(sum)
        })
          .catch((err) => {});
    }
    getallFiles();
  },[])
  

  const sumTime = (times) => {
    //console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

//   const imageclick = (imgpath) => {
//     console.log("imgpath", imgpath)
//     setImgSrc(imgpath)
//   }

//   const onQCRejectFile = async(fid, duration, userId, e) => {

//     const headers = reqHeader(true, true, true);
//       let reason = getValues("reasonforrejection");
//     if(reason){
//       let body={
//         id:fid,
//         reason:reason,
//         role: "Coordinator",
//         fileDuration: duration,
//         userID: userId,
//       }
//       console.log("body", body)
//       const config = { headers };
//       let url = `${process.env.REACT_APP_URL}/rejectfile`;

//       await axios
//         .put(url,body,config)
//         .then((res) => {
//           let rejectbtn=document.getElementById(e.target.id);
//           rejectbtn.disabled=true;
//         });
//     }else{
//       toast.error("Please select reason for rejection")
//     }
      
// }


  let columns = useMemo(
    () => [
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text"
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "Vendor Name",
        accessor: "vendorName",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.status == "Accepted" && row.original.isQcAccepted ? "Accepted" : row.original.status == "Accepted" && !row.original.isQcAccepted ? "QCPending" : row.original.status}
            </div>
          );
        },
      },
      // {
      //   Header: "Reason for rejection",
      //   //accessor: "recordedOn",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       row.original.status == "Accepted" && (!row.original.isQcAccepted)?
      //       <div>
      //           <div className = {`inputWrap ${
      //                    errors.reasonforrejection ? "error" : ""
      //                 }`}>
      //                 <select
      //                     {...register("reasonforrejection")}
      //                     onChange={(e) => setValue('reasonforrejection', e.target.value,  { shouldValidate: true })} // Using setValue
      //                   >
      //                      <option value="">Please select reason</option>
      //                      <option value="Audio > 25 seconds">Audio &gt; 25 seconds</option>
      //                      <option value="Audio < 10 seconds">Audio &lt; 10 seconds</option>
      //                      <option value="Not audible">Not audible</option>
      //                      <option value="Background Noise">Background Noise</option>
      //                      <option value="Gender mismatch">Gender mismatch</option>
      //                      <option value="Image & Audio mismatch">Image & Audio mismatch</option>
      //                      <option value="Speech is not natural">Speech is not natural</option>
      //                      <option value="Multiple speakers">Multiple speakers</option>
      //                      <option value="Abusive language">Abusive language</option>
      //                      <option value="Language Mismatch">Language Mismatch</option>
      //                   </select>
      //                   {errors.reasonforrejection && (
      //                   <span className="inputErrorMsg">
      //                     {errors.reasonforrejection?.message}
      //                   </span>
      //                 )}
      //               </div>
      //       </div> : ""
      //     );
      //   },
      // },
      // {
      //   Header: "Reject",
      //   accessor: "",
      //   sortType: "basic",
      //   filter: "text",
      //   // fixed: "left",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       row.original.status == "Accepted" && (!row.original.isQcAccepted)?
      //       <div>
      //         <button id={`rejbtn${row.original._id}`}            
      //           onClick={(e) => onQCRejectFile(row.original._id, row.original.fileDuration, row.original.userID, e)}
      //           className="btn blueBtn-fill">
      //           Reject
      //         </button>

      //       </div> : ""
      //     );
      //   }
      // },
    ],
    []
  );

  // const headers = [
  //   { label: "Image name", key: "imageName" },
  //   { label: "File name", key: "fileName" },
  //   { label: "Duration", key:"fileDuration" },
  //   { label: "Vendor name", key: "vendorName" },
  //   { label: "Recorded On", key:"recordedOn" },
  //   { label: "State", key: "state"},
  //   { label: "District", key: "district"},
  //   { label: "Coordinator", key: "coordinatorName"},
  //   { label: "Status", key: "status" },
  //   // { label: "Rate", key: "rate" },
  //   // { label: "Paid", key: "isPaid"},
  // ];

  const menuToggler = () =>{
    const leftSideBar = document.querySelector(".leftSideBar")
    leftSideBar.style.display = "block"
  }

    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
        <img src={Logo} alt="Logo" className="logoImg"/>
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Coordinator File List
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between totalBox">
                <div className="adminDetails d-flex ">
                  <div>
                  <img src={Omni} alt="Omni"/>
                  <div className="d-flex flex-column">
                    <label>Supervisor Name:</label>
                    <span className="value">{userLogin.user.supervisorName}</span>
                  </div>
                  </div>
                  <div style={{paddingLeft:"15px", borderLeft:"1px solid #D9D9D9"}}>
                  <img src={Mic} alt="Mic" />
                  <div className="d-flex flex-column">
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                  </div>
                </div>
                {/* <CSVLink data={fileList} filename={userLogin.user.state + "_" + userLogin.user.district + "_" + userLogin.user.name} headers={headers} className="btn btnExcelGreen" target="_blank">
                 <img src={Excel} alt="Excel"/> Export FileList
                </CSVLink> */}
                {/* <button className="btn blueBtn-fill">Excel Export</button> */}
              </div>
            
            </div>

            {/* <div className="hover_img" style={{textAlign:"end"}}>
                <a href="#"><span><img src={imgSrc} alt="" style={{width:"200px", height:"200px"}}/></span></a>
            </div> */}
            
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default CoordinatorFileList;