import React, { useEffect, useMemo, useState } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import reqHeader from "../helper/reqHeader";
import Table from "./Table/Table";
import {
  Alert,
  CustomDialog,
  Confirm,
  Dialog,
  useDialog,
} from "react-st-modal";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import {BsPauseCircleFill} from "react-icons/bs";


const schema = yup.object().shape({
  coordinator: yup.string().required("Coordinator is required!"),
});

const QcRejectedLangMismatchFiles = () => {
  const location = useLocation();
  const { speakerID } = location.state;
  //console.log("location.state", location.state);

  const userLogin = useSelector((state) => state.userLogin);
 
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [imgSrc, setImgSrc] = useState([]);
  // const [fileLang, setFileLang] = useState("");

  const dialog = useDialog();

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(() => {
    // disable right click
    document.addEventListener("contextmenu", (event) => event.preventDefault());

    document.onkeydown = function (e) {
      // disable F12 key
      if (e.keyCode == 123) {
        return false;
      }

      // disable I key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
        return false;
      }

      // disable J key
      if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
        return false;
      }

      // disable U key
      if (e.ctrlKey && e.keyCode == 85) {
        return false;
      }
    };
  }, []);


  useEffect(() => {
    if(userLogin){
      setState(userLogin.user.state)
      setDistrict(userLogin.user.district)
    }

  }, []);


  useEffect(() => {
    async function getRejectedLangMismatchFiles() {
      const url = `${process.env.REACT_APP_URL}/getrejectedLangMismatchfiles`;
      const headers = reqHeader(true, true, true);
      const params = {speakerID}
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("res.data.data", res.data.data)

          setFileList(res.data.data);

        
          // const boolsorted = res.data.data.sort(
          //   (a, b) => a.isQcAccepted - b.isQcAccepted
          // );
          // let sorted = boolsorted.sort((a, b) =>
          //   a.status.localeCompare(b.status)
          // );

           //setFileList(sorted);

          //disable all Accept Reject buttons
          // res.data.data.map((frow) => {
          //   let acbtn = document.getElementById(`acpbtn${frow._id}`);
          //   acbtn.disabled = true;
          //   let rejectbtn = document.getElementById(`rejbtn${frow._id}`);
          //   rejectbtn.disabled = true;
          // });

          const times = [];
          res.data.data.map((filedata) => {      
              times.push(filedata.fileDuration);
          });
          const sum = sumTime(times);
          setTotalDuration(sum);
        })
        .catch((err) => {});
    }
    getRejectedLangMismatchFiles();
  }, []);


  // useEffect(() => {
  //   const audioElement = document.getElementById("myaudio");
  //   console.log("audioElement", audioElement);
  //   if (audioElement) {
  //     // Event listener for when the audio starts playing
  //     audioElement.addEventListener("play", () => {
  //       console.log("Audio started playing");
  //     });

  //     // Event listener for when the audio ends
  //     audioElement.addEventListener("ended", () => {
  //       console.log("Audio playback finished");
  //       // Here, you could assume that the user listened to the complete audio.
  //       // However, this is an assumption and might not always be accurate.
  //     });
  //   }
  // }, []);

  const sumTime = (times) => {
    console.log("Inside sumtime", times);
    let sumSeconds = 0;

    times.forEach((time) => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });

    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - hours * 3600) / 60);
    var seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ":" + minutes + ":" + seconds;
  };



  const imageclick = (imgpath) => {
    console.log("imgpath", imgpath);
    setImgSrc(imgpath);
  };

  const onChangeDistrictLanguage = (e, fid) => {
    setValue('districtlanguage', e.target.value,  { shouldValidate: true })
    //setFileLang(e.target.value);
    //console.log("e.target.value", e.target.value)
  }


  const onQCAcceptFile = async (fid, e) => {
      
      let selectedLang = getValues("districtlanguage");
      if(selectedLang){
        let acbtn = document.getElementById(e.target.id);
        acbtn.disabled = true;
        let rejectbtn = document.getElementById(`rejbtn${fid}`);
        rejectbtn.disabled = true;
    
        const headers = reqHeader(true, true, true);
  
        //console.log("fileLang inside", selectedLang, fid)
    
        let body = {
          id: fid,
          language: selectedLang,
          speakerID: speakerID,
        };
    
        const config = { headers };
        let url = `${process.env.REACT_APP_URL}/qclanguagemismatchacceptfile`;
        await axios.put(url, body, config).then((res) => {
            //toast.success(res.data.msg);
          // let acbtn= document.getElementById(e.target.id);
          // acbtn.disabled=true;
          // let rejectbtn=document.getElementById(`rejbtn${fid}`);
          // rejectbtn.disabled=true;
          //window.location.reload();
        });
      }else{
        toast.error("Please select correct language!")
      }


  };

  const onQCRejectFile = async (fid, e) => {
    console.log("Inside reject");
    const headers = reqHeader(true, true, true);
      let acbtn = document.getElementById(`acpbtn${fid}`);
      acbtn.disabled = true;
      let rejectbtn = document.getElementById(e.target.id);
      rejectbtn.disabled = true;

      let body = {
        id: fid
      };
      //console.log("body", body)
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/qcrecoveryrejectfile`;

      await axios.put(url, body, config).then((res) => {
        // let acbtn= document.getElementById(`acpbtn${fid}`);
        // acbtn.disabled=true;
        // let rejectbtn=document.getElementById(e.target.id);
        // rejectbtn.disabled=true;
        //window.location.reload();
      });

  };

  // const audioPause = (fid) => {
  //   const audioElement = document.getElementById(fid);
  //   audioElement.pause();
  // };

  // const audioPlay = (e, fid) => {
  //   let audios = document.getElementsByTagName("audio");
  //   for (var i = 0, len = audios.length; i < len; i++) {
  //     if (audios[i] != e.target) {
  //       audios[i].pause();
  //     }
  //   }
  //   const audioElement = document.getElementById(fid);

  //   // audioElement.paused ? audioElement.play() : audioElement.pause();
  //   audioElement.play();

  //   let progressBar = e.target.parentElement.children[2].children[0]
  //   audioElement.addEventListener("timeupdate", () => {
  //     progressBar.style.width = (100*audioElement.currentTime/audioElement.duration) + "%"
  //   })

  //   // Event listener for when the audio ends
  //   audioElement.addEventListener("ended", () => {
  //     console.log("Audio playback finished");
  //     // Here, you could assume that the user listened to the complete audio.
  //     // However, this is an assumption and might not always be accurate.
  //     let acbtn = document.getElementById(`acpbtn${fid}`);
  //     acbtn.disabled = false;
  //     let rejectbtn = document.getElementById(`rejbtn${fid}`);
  //     rejectbtn.disabled = false;
  //   });
  // };

 

  let columns = useMemo(
    () => [
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return <div>{row.original.fileName}</div>;
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div onClick={(e) => imageclick(row.original.imageLocation)}>
              {row.original.imageName}
            </div>
          );
        },
      },
      {
        Header: "Audio",
        //accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          //console.log("row", row)
          return (
            // <div style={{display:'flex',flexWrap:"wrap", gap:"0.15rem"}}>
            //   {/* <input type="button" value="PLAY" onClick={e => audioPlay(e.target.value, row.original._id)} /> */}
            //   <button
            //     onClick={(e) => audioPlay(e, row.original._id, row.original)}
            //   >
            //     <audio
            //       src={row.original.fileLocation}
            //       id={row.original._id}
            //       controls={false}
            //     />
            //     Play
            //   </button>
            //   <BsPauseCircleFill onClick={(e) => audioPause(row.original._id)} style={{
            //     fontSize : "1rem",
            //     cursor: 'pointer'
            //   }}/>
            //   <div className="progress_bar" style={{
            //     height:'5px',
            //     width:'100%',
            //     backgroundColor:'gray',
            //     borderRadius:'6px'
            //   }}>
            //     <div className="progressed"
            //     style={{
            //       height:'5px',
            //       borderRadius:'6px',
            //       width:"0px",
            //       backgroundColor:"#78cf4d",
            //       transition: 'all 1.2s'
            //     }}
            //     ></div>
            //   </div>
            //   {row.original.fileDuration}
            // </div>
            <div>
            <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
          </div>
          );
        },
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "District Languages",
        //accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <div className = {`inputWrap ${
                         errors.districtlanguage ? "error" : ""
                      }`}>
                      <select
                          {...register("districtlanguage")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeDistrictLanguage(e, row.original._id)}
                        >
                          {}
                           <option value="">Please select correct language</option>
                          {row.original.distLanguages && row.original.distLanguages.map((lang)=>{
                            return <option key={lang} value={lang}>{lang}</option>
                          })}
                        </select>
                        {errors.districtlanguage && (
                        <span className="inputErrorMsg">
                          {errors.districtlanguage?.message}
                        </span>
                      )}
              </div>
            </div>
          )
        },
      },
      {
        Header: "Accept",
        //accessor: "qcaccept",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return(
            <div>
            <button
              id={`acpbtn${row.original._id}`}
              onClick={(e) => onQCAcceptFile(row.original._id, e)}
              className="btn blueBtn-fill"
            >
              Accept
            </button>
          </div>
          )
        },
      },
      {
        Header: "Reject",
        accessor: "",
        sortType: "basic",
        filter: "text",
        // fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <button
                id={`rejbtn${row.original._id}`}
                onClick={(e) =>
                  onQCRejectFile(
                    row.original._id,
                    e
                  )
                }
                className="btn blueBtn-fill"
              >
                Reject
              </button>
            </div>
          )
        },
      },
      // {
      //   Header: "Status",
      //   accessor: "status",
      //   sortType: "basic",
      //   filter: "text",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <div>
      //        {row.original.status}
      //       </div>
      //     );
      //   },
      // },
    ],
    []
  );

  return (
    <>
      <div className="container entitiCustomerWrap d-flex">
        <LeftNavigation />
        <ToastContainer />
        <div className="pageRight">
          <div className="userSection">
            <div className="welcomeUser">Quality Checker</div>

            <UserProfile />
          </div>

          <div>

            <div className="d-flex justify-between">
              <div className="adminDetails">
                <div>
                  <label>Total Audio Duration:</label>
                  <span className="value">{totalDuration}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="hover_img" style={{ textAlign: "end" }}>
            <a href="#">
              <span>
                <img
                  src={imgSrc}
                  alt=""
                  style={{ width: "200px", height: "200px" }}
                />
              </span>
            </a>
          </div>
          <div className="projectCoordinatorContent">
            <div className="projectContent">
              <Table
                columns={columns}
                data={fileList}
                count={fileList.length}
                pagination={false}
                expanded={false}
                isHeader={true}
                filter={true}
                isCountDisplayed={true}
                customText="Files"
                pageType="qcuserfilelist"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QcRejectedLangMismatchFiles;
