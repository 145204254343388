import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import { Link, Redirect, useHistory } from "react-router-dom";
import PopupEditTeamlead from "./Pop-ups/admin-edit-teamlead";

const AdminTeamleadList = () =>  {
  const [teamleads, setTeamLeads] = useState([]);

  useEffect(()=>{
    async function getTeamleadList() {
      const url = `${process.env.REACT_APP_URL}/getteamleadlist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)

      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("setTeamleads", res.data.data);
          setTeamLeads(res.data.data);
        })
        .catch((err) => {});
    }
    getTeamleadList();
  },[])

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "States",
        accessor: "states",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Districts",
        accessor: "districts",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Password",
        accessor: "accesscode",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Edit",
        accessor: "id",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
                {/* <Link onClick={async (e) => {
                  const result = await CustomDialog(
                    <PopupEditTeamlead teamleadID={row.original.id} />,
                    {
                      title: "Edit Teamlead(Assign Districts)",
                      showCloseIcon: true,
                    }
                  );
                }}>Edit</Link> */}

              <button className="btn adminUserBtn"  onClick={async (e) => {
              const result = await CustomDialog(
                <PopupEditTeamlead teamleadID={row.original.id} />,
                {
                  title: "Edit Teamlead(Assign Districts)",
                  showCloseIcon: true,
                }
              );
            }}>Edit</button>
            </div>
          );
        },
      }
    ],
    []
  );


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                user Details
              </div>

              <UserProfile />
            </div>

            <div className="d-flex justify-end regionSelectWrap">

              </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={teamleads}
                      count={teamleads.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Teamlead-List"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AdminTeamleadList;