import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import reqHeader from "../helper/reqHeader"
import LeftNavigation from "./left-navigation";
import { SearchIcon } from "./icons.component";
import UserProfile from "./userProfile";
import Table from "./Table/Table";
import PopupCustomerUserDetails from "./Pop-ups/customer-user-details";
import { Alert, CustomDialog, Confirm } from "react-st-modal";
import { CSVLink } from "react-csv";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup.object().shape({
  state: yup
    .string()
    .required("State is required!"),
  district: yup
    .string()
    .required("district is required!"),
  // coordinator: yup
  //   .string()
  //   .required("Coordinator is required!"),
});

const CustomerUserList = () => {

  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [users, setUsersList] = useState([])
  const [fileList, setFileList] = useState([]);
  const userLogin = useSelector((state) => state.userLogin);

  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      async function getDistricts() {
      const url = `${process.env.REACT_APP_URL}/getdistricts`;
      const headers = reqHeader(true, true, true);
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          setDistricts(
            res.data.data.map((district) => ({ label: district, value: district }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getDistricts();
  }, [state]);


  useEffect(()=>{
    async function getUsers() {
      const url = `${process.env.REACT_APP_URL}/getdistrictwiseusers`;
      const headers = reqHeader(true, true, true);
      const params = {state, district}
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          setUsersList(res.data.data)

       })
        .catch((err) => {});
    }
    getUsers();

  },[district]);

  const onChangeState = (e) => {
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);

  }


  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "UserId",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
        Cell: ({ value, column, row }) => {
          return (
            <div
              title="Click for the detailed File List"
              style={{ cursor: "pointer" }}
              onClick={async (e) => {
                const result = await CustomDialog(
                  <PopupCustomerUserDetails mobile={row.original.mobile} />,
                  {
                    title: "User Details",
                    showCloseIcon: true,
                    //className: "fullPagePopup"
                  }
                );
              }}
            >
              {row.original.mobile.slice(2)}
            </div>
          );
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      {
        Header: "Total Duration",
        accessor: "recordedHours",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Paid",
        accessor: "isPaid",
        sortType: "basic",
        filter: "text",
       
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.isPaid ? "Yes" : "No"}
            </div>
          );
        },
      },
      // {
      //   Header: "Password",
      //   accessor: "accesscode",
      //   sortType: "basic",
      //   filter: "text",
      //   //fixed: "left",
      //   Cell: ({ value, column, row }) => {
      //     return (
      //       <button className="btn blueBtn-fill" onClick={(e) => resendOTP(row.original)}>Resend Password</button>
      //     );
      //   },
      // },
    ],
    []
  );


    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Assign for Transcription
              </div>

              <UserProfile />
            </div>
            <div>

              <div className="d-flex justify-between regionSelectWrap">
              <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.state ? "error" : ""
                      }`}>
                      <select
                          {...register("state")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeState(e)}
                        >
                          {}
                           <option value="">Please select State</option>
                          {states && states.map((state)=>{
                            return <option key={state.value} value={state.value}>{state.label}</option>
                          })}
                        </select>
                        {errors.state && (
                        <span className="inputErrorMsg">
                          {errors.state?.message}
                        </span>
                      )}
                    </div>
                     <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                      <select className="error"
                          {...register("district")}
                          // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                            onChange={(e) => OnChangeDistrict(e)}
                        >
                           <option value="">Please select District</option>
                          {districts && districts.map((district)=>{
                            return <option key={district.value} value={district.value}>{district.label}</option>
                          })}
                        </select>
                        {errors.district && (
                        <span className="inputErrorMsg">
                          {errors.district?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>
            </div>
            <div className="projectCoordinatorContent">
                <div className="d-flex justify-end">
                </div>

              <div className="projectContent">
                {/* <div className="EntitiVendorTable"> */}
                    <Table
                      columns={columns}
                      data={users}
                      count={users.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="users"
                    />
                  {/* </div> */}
              </div>
            </div>
            
          </div>
        </div>
      </>
    );
};

export default CustomerUserList;