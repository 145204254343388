import React, { useEffect, useMemo, useState, useRef } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import axios from "axios";
import reqHeader from "../helper/reqHeader"
import Table from "./Table/Table";
import { Alert, CustomDialog, Confirm, Dialog, useDialog } from "react-st-modal";
import {useLocation} from 'react-router-dom';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";


const schema = yup.object().shape({
  // coordinator: yup
  //   .string()
  //   .required("Coordinator is required!"),
})

// const IndeterminateCheckbox = React.forwardRef(
//   ({ indeterminate, ...rest }, ref) => {
//     const defaultRef = useRef();
//     const resolvedRef = ref || defaultRef;

//     useEffect(() => {
//       resolvedRef.current.indeterminate = indeterminate;
//     }, [resolvedRef, indeterminate]);

//     return (
//       <>
//         <input type="checkbox" ref={resolvedRef} {...rest} />
//       </>
//     );
//   }
// );

const AdminDistrictUserFileList = () => {
  const location = useLocation();
  const {speakerID, mobile} = location.state;
  // console.log("location.state", location.state)
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });


  const [fileList, setFileList] = useState([]);
  const [totalDuration, setTotalDuration] = useState();
  const [roleBaseDetails, setRoleBaseDetails] = useState({});
  const [imgSrc, setImgSrc] = useState([]);
  const [intraonespeaker, setIntraOneSpeaker] = useState();
  const [selectedRowsValues, setSelectedRowsValues] = useState({});
  const [isAccepted, setIsAccepted] = useState(false);
  const [isHold, setIsHold] = useState(false)
  const [speakerCount, setSpeakerCount] = useState();
  const dialog = useDialog();

  //Disable Right click, F12, ctrl+shift+I, ctrl+shift+J
  useEffect(()=>{
      // disable right click
      document.addEventListener('contextmenu', event => event.preventDefault());
   
      document.onkeydown = function (e) {
   
          // disable F12 key
          if(e.keyCode == 123) {
              return false;
          }
   
          // disable I key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 73){
              return false;
          }
   
          // disable J key
          if(e.ctrlKey && e.shiftKey && e.keyCode == 74) {
              return false;
          }
   
          // disable U key
          if(e.ctrlKey && e.keyCode == 85) {
              return false;
          }
      }
  },[])

  useEffect(()=>{
    async function getUserDetails() {
      const url = `${process.env.REACT_APP_URL}/getuserdetails`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = {
        mobile, speakerID
      }
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {

          const times = [];

          res.data.data.map((filedata)=>{           
              times.push(filedata.fileDuration)        
            })
            setFileList(res.data.data)
            const sum = sumTime(times)
            setTotalDuration(sum)
       })
        .catch((err) => {});
    }
    getUserDetails();

  },[])

  const sumTime = (times) => {
    //console.log("Inside sumtime", times)
    let sumSeconds = 0;
      
    times.forEach(time => {
      let a = time.split(":");
      let seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
      sumSeconds += seconds;
    });
    
    //return new Date(sumSeconds * 1000).toISOString().substr(11, 8);
    var sec_num = parseInt(sumSeconds, 10); // don't forget the second param
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}
    //console.log("sum total", hours + ':' + minutes + ':' + seconds);
    return hours + ':' + minutes + ':' + seconds;
  }

  const imageclick = (imgpath) => {
    //console.log("imgpath", imgpath)
    setImgSrc(imgpath)
  }
  

  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "File name",
        accessor: "fileName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {row.original.fileName}
            </div>
          );
        },
      },
      {
        Header: "Image name",
        accessor: "imageName",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div onClick={(e) =>imageclick(row.original.imageLocation)}>
              {row.original.imageName}
            </div>
          );
        }
      },
      {
        Header: "Audio",
        accessor: "fileLocation",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <audio src={row.original.fileLocation} controls controlsList="nodownload"></audio>
            </div>
          );
        },
      },
      {
        Header: "Gender",
        accessor: "gender",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Language",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Recorded on",
        accessor: "recordedOn",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              {format(
                    new Date(row.original.recordedOn),
                    "dd/MM/yyyy"
                  )}
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
             {row.original.status}
            </div>
          );
        },
      },
    ],
    []
  );


    return (
      <>
       
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                User File List
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between">
                <div className="adminDetails">
                  <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div>
                </div>

              </div>
            </div>
            <div className="hover_img" style={{textAlign:"end"}}>
            <a href="#"><span><img src={imgSrc} alt="" style={{width:"200px", height:"200px"}}/></span></a>
            </div>
            <div className="fileListTable">
            <div className="tableTopHeader" style={{display:"inline-flex"}}>
              {/* {console.log("!isSpeaker1, !isSpeaker2, !isSpeaker3", isSpeaker1, isSpeaker2, isSpeaker3)} */}
              {/* <button className="btn blueBtn-fill" style={{marginLeft:"10px"}} onClick={handleFileAcceptBatch} disabled={!isAccepted} >Accept</button>
             
              <button className="btn blueBtn-fill" style={{marginLeft:"10px"}}  onClick={handleFileHoldBatch} disabled={!isHold}>Hold</button> */}
             
              </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={fileList}
                      count={fileList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Files"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );

};

export default AdminDistrictUserFileList;