import React , { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../../helper/reqHeader.js";
import axios from "axios";

const schema = yup.object().shape({
  language: yup
    .string()
    .matches(/^[a-zA-Z]+$/, "Invalid Language")
    .required("Language is required!"), 
});


const PopupAddLanguage = () => {

  // const {
  //   register,
  //   handleSubmit,
  //   control,
  //   trigger,
  //   formState: { errors },
  //   setValue,
  //   getValues
  // } = useForm({
  //   mode: "onSubmit",
  //   // reValidateMode: "onChange",
  //   resolver: yupResolver(schema)
  // });

  const methods = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });

  const { register, handleSubmit, errors, setValue, getValues } = methods;

  const [language, setLanguage] = useState();



  const submitHandler = async(data) => 
  {
          const url = `${process.env.REACT_APP_URL}/addnewparticipantlanguage`;
          //console.log("url..",url);
          const headers = reqHeader(true, true, true);
   
          const config = {
              headers,
           };
 
          const body = {
           language:data.language,
          };

          try {
            const response = await axios.post(url, body, config);
            toast.success(response.data.msg)
            setTimeout(()=>{	
             window.location.reload();	
           }, 3000)
 
          } catch (error) {
            //console.log(error.response.data.error);
            toast.error(error.response.data.error);
            //toast.error("Incorrect Details");
          }
       
     };

  return (
      <div className="addNewUserPop" style={{margin:"10px"}}>
          {/* <ToastContainer /> */}
        <br/>
        <form className="form" onSubmit={handleSubmit(submitHandler)}>
          <div className="popupForm">
            <div className="formRow">
              <div className="inputBlock">
                <label>language<span className="requiredField">*</span></label>
                <div className={`inputWrap ${errors.language ? "error" : ""}`}>
                  <input
                        type="text"
                        placeholder="language"
                        name="language"
                        ref={register}
                      />
                      {errors.language && (
                        <span className="inputErrorMsg">
                          {errors.language?.message}
                        </span>
                      )}
                 </div>
              </div>
            </div>
          </div>

          <div className="footerAction d-flex justify-center" style={{margin:"10px"}}>
            <button className="adminUserBtn btn">Add</button>
          </div>
        </form>
      </div>
    );
};

export default PopupAddLanguage;
