import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation.jsx";
import UserProfile from "./userProfile.jsx";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table.jsx";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import { Confirm } from 'react-st-modal';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-toastify/dist/ReactToastify.css";


const AdminLangApprovalList = () =>  {
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    //resolver: yupResolver(schema),
  });

  const [approvalLangList, setApproveLanglist] = useState([]);

  useEffect(()=>{
    async function getApprovalLangList() {
      const url = `${process.env.REACT_APP_URL}/getapprovallanglist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)

      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("res", res.data.data);
          setApproveLanglist(res.data.data);
        })
        .catch((err) => {});
    }
    getApprovalLangList();
  },[]);


  let columns = useMemo(
    () => [
      {
        Header: "Language Name",
        accessor: "language",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Reason",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return(
            <div>
                <select
                  {...register("reason")}
                  onChange={(e) =>
                    setValue("reason", e.target.value, {
                      shouldValidate: true,
                    })
                  } // Using setValue
                >
                  <option value="">Please select reason</option>
                  {/* <option value="Audio > 25 seconds">Audio &gt; 25 seconds</option>
                           <option value="Audio < 10 seconds">Audio &lt; 10 seconds</option> */}
                  <option value="optionA">Correct new lanuage</option>
                  <option value="optionB">New Language but spelling mistake</option>
                  <option value="optionC">
                    Language already exist, same added with spelling mistake
                  </option>
                </select>  
              </div>
          ) 
        },
      },
      {
        Header: "Edit",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div className="inputWrap">
              <input
                type="text"
                placeholder="language"
                name="language"
                onChange={(e) =>
                  setValue("editedLanguage", e.target.value, {
                    shouldValidate: true,
                  })
                } // Using setValue
                {...register("editedLanguage")}
                //ref={register}
              />
            </div> 
          );
        }
      },
      {
        Header: "Update",
        accessor: "",
        sortType: "basic",
        filter: "text",
        Cell: ({ value, column, row }) => {
          return (
            <div>
              <button           
                onClick={(e) => updateLanguage(row.original._id, row.original.language,)}
                className="btn blueBtn-fill">
                Update
              </button>

            </div> 
          );
        }
      },
    ],
    []
  );

  const updateLanguage = async(rowid, oldlang) => {

    const headers = reqHeader(true, true, true);
    let newlang = getValues("editedLanguage");
    let reason = getValues("reason");
    //console.log("reason", reason);
    if (newlang && reason) {

      let body = {
        id: rowid,
        oldlang: oldlang,
        newlang: newlang.trim(),
        reason: reason
      };
      console.log("body", body)
      const config = { headers };
      let url = `${process.env.REACT_APP_URL}/updateandapprovelang`;

      await axios.put(url, body, config).then((res) => {
        console.log("res", res)
        toast.success(res.data.msg);
       setTimeout(()=>{
        window.location.reload();
       },2000)
         
      });
    } else {
      toast.error("Please select reason and enter new language");
    }
  }


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <ToastContainer />
          <LeftNavigation />

          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                QC Details
              </div>

              <UserProfile />
            </div>
            <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={approvalLangList}
                      count={approvalLangList.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Approval-Language-List"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AdminLangApprovalList;