import React , { useEffect, useState, useMemo } from "react";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logo from '../assets/images/Logo.svg';
import {BiMenuAltRight} from "react-icons/bi"


const SupervisorCoordinatorList = () =>  {
  const [coordinators, setCoordinators] = useState([]);

  useEffect(()=>{
    async function getSupervisorCoordinatorList() {
      const url = `${process.env.REACT_APP_URL}/getsupervisorcoordinatorlist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("setCoordinators", res.data.data);
          setCoordinators(res.data.data);
        })
        .catch((err) => {});
    }
    getSupervisorCoordinatorList();
  },[])

  let columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "State",
        accessor: "state",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "District",
        accessor: "district",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "Password",
        accessor: "accesscode",
        sortType: "basic",
        filter: "text",
        //fixed: "left",
        Cell: ({ value, column, row }) => {
          return (
            <button className="btn darkGreenBtn" onClick={(e) => resendOTP(row.original)}>Resend Password</button>
          );
        },
      },
    ],
    []
  );


  const resendOTP = async(row) => {

    const url = `${process.env.REACT_APP_URL}/resendotp`;
    const headers = reqHeader(true, true, true);
    console.log("header", headers)
    const params = {
      mobile: row.mobile, accesscode:row.accesscode
    }
    const config = { params, headers };
    await axios
      .get(url, config)
      .then((res) => {
        if(res.data.success){
          toast.success("OTP sent Successfully!")
        }
      })
        .catch((err) => {
          toast.error(err.response.data)
        });
}
const menuToggler = () =>{
  const leftSideBar = document.querySelector(".leftSideBar")
  leftSideBar.style.display = "block"
}

    return (
      <>
        <div className="container entitiCustomerWrap d-flex mobileView">
        <img src={Logo} alt="Logo" className="logoImg"/>
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                user Details
              </div>
              <BiMenuAltRight className="toggleMenu"
              onClick={() => menuToggler()}/>
              <UserProfile />
            </div>
            {/* <div>
            <div className="d-flex justify-end regionSelectWrap">
      
            </div>
            </div> */}
            <div className="projectCoordinatorContent">
              <div className="projectContent mobileDashboard">
                    <Table
                      columns={columns}
                      data={coordinators}
                      count={coordinators.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Coordinator-List"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default SupervisorCoordinatorList;