import axios from 'axios';
import * as yup from "yup";
import React, { useState } from 'react'
import { useForm } from 'react-hook-form/dist';
import { yupResolver } from "@hookform/resolvers/yup";
import { ToastContainer, toast } from 'react-toastify/dist';
import getBlobDuration from 'get-blob-duration';
import RegisterAudio from './register-audio';
import Logo from '../assets/images/Logo.svg';
import { PasswordHide, PasswordShow } from './icons.component';
// import { useHistory } from "react-router-dom";
import { Link, Redirect, useHistory } from "react-router-dom";

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const schema = yup.object().shape({
    mobile: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required('A mobile number is required'),
    accesscode: yup
        .string()
        .required("Access code is required !!!"),
});
const VoiceVerify = () => {
    const [passwordShown, setPasswordShown] = useState(false);
    const [loading, setLoading] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const history = useHistory();

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
    });
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };

    const submitHandler = async (data) => {
        if (!audioBlob) {
            toast.error("Please record your audio");
            return;
        }
        const duration = await getBlobDuration(audioBlob);

        if (duration < 10 || duration > 25) {
            toast.error("Audio duration should be between 10 to 25 seconds");
            return;
        }

        const form = new FormData();
        form.append("mobile", data.mobile);
        form.append("accesscode", data.accesscode);
        form.append("audio", audioBlob);

        try {
            setLoading(true);
            const res = await axios.post(`${process.env.REACT_APP_URL}/verifyVoice`, form, {})
            const message = res.data.message
            toast.success(message);
            setLoading(false);
            setTimeout(() => {
                history.push("/")
            }, 3000)
        } catch (error) {
            setLoading(false);
            const message = error.response.data.error || "Voice verification failed";
            toast.error(message);
        }

    }

    const handleAudioBlobData = async (data) => {
        setAudioBlob(data);
    }

    return (
        <>
            <div className="container RegisterWrap d-flex h-100vh">
                <ToastContainer />
                <div className="registerLHS">
                <Link to="/" className="logo"><img src={Logo} alt="Logo" /></Link>
                    {/* <img src={Logo} alt="Logo" className="logo" /> */}
                    <div className="loginFooter">
                        Copyright © Megdap Innovation Labs | <span className='cursorPointer'>Contact Us</span>
                    </div>
                </div>
                <div className="registerRHS">
                    <div className="registrationForm loginForm">
                        <h2 className="loginTitle">Voice Verification</h2>
                        <form className="form" onSubmit={handleSubmit(submitHandler)}>
                            <div className="registerFormWrap">
                                <div
                                    className={`inputWrap emailField ${errors.mobile ? "error" : ""
                                        }`}>
                                    <input
                                        ref={register}
                                        type="number"
                                        placeholder="Mobile Number"
                                        name="mobile"
                                    />

                                    {errors.mobile && (
                                        <span className="inputErrorMsg">
                                            {errors.mobile?.message}
                                        </span>
                                    )}
                                </div>

                                <div
                                    className={`inputWrap passwordField ${errors.accesscode ? "error" : ""
                                        }`}>
                                    <input
                                        ref={register}
                                        type={passwordShown ? "text" : "password"}
                                        placeholder="Password"
                                        name="accesscode"
                                    />
                                    <button type='button' className="passwordEye">
                                        <i onClick={togglePasswordVisiblity}>
                                            {passwordShown ? <PasswordShow /> : <PasswordHide />}
                                        </i>{" "}
                                    </button>
                                    {errors.accesscode && (
                                        <span className="inputErrorMsg">
                                            {errors.accesscode?.message}
                                        </span>
                                    )}
                                </div>

                                <RegisterAudio handleAudioBlobData={handleAudioBlobData} />

                                <div className='formFooter mt-2'>
                                    <button className={`roundedBtn-Blue ${loading ? 'btn-disabled' : ''}`} disabled={loading}>{loading ? "Verifying..." : "Verify"}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default VoiceVerify
