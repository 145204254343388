import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGOUT,
} from "../constants/userConstants.js";

export const userLoginReducer = (state = {}, action) => {
  const { type, payload } = action;

  // console.log("User Reducer Type is ", type);

  switch (type) {
    case USER_LOGIN_REQUEST:
      return { loading: true, authenticated: false };
    case USER_LOGIN_SUCCESS:
      return { ...payload, loading: false, authenticated: true };
    case USER_LOGIN_FAIL:
    case USER_LOGOUT:
      return { ...payload, loading: false, authenticated: false };
    default:
      return state;
  }
};
