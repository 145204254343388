import React , { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import LeftNavigation from "./left-navigation";
import UserProfile from "./userProfile";
import { useForm, Controller } from "react-hook-form";
import { Link, Redirect, useHistory} from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../helper/reqHeader.js";
import axios from "axios";
import Table from "./Table/Table";
// import QcRejectFile from "./Pop-ups/qc-reject-file"

const schema = yup.object().shape({
  coordinator: yup
    .string()
    .required("Coordinator is required!"),
});

const QcOldUserList = () => {

  const history = useHistory();
  const userLogin = useSelector((state) => state.userLogin);

 
  const {
    register,
    //handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onChange",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [coordinators, setCoordinators] = useState([]);
  const [coordinator, setCoordinator] = useState([]);
  const [users, setUsersList] = useState([])
  const [roleBaseDetails, setRoleBaseDetails] = useState({});


  useEffect(() => {
    if(userLogin){
      setState(userLogin.user.state)
      setDistrict(userLogin.user.district)
    }

  }, []);
  

  useEffect(() => {
    
    async function getcoordinators() {
      const url = `${process.env.REACT_APP_URL}/getoldcoordinatorsforqc`;
      const headers = reqHeader(true, true, true);
      console.log("state, district", state, district)
      const params = {
        state:state,
        district:district,
       };

      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Coordinator", res.data.data);
          setCoordinators(
            res.data.data.map(( coordinator ) => ({ label: coordinator, value: coordinator }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getcoordinators();
    
  }, [district]); 

   
  const onChangeCoordinator = (e) => {
    setValue('coordinator', e.target.value,  { shouldValidate: true })
    setCoordinator(e.target.value);

    async function getUsers() {
        const url = `${process.env.REACT_APP_URL}/getoldinteracceptedusers`;
        const headers = reqHeader(true, true, true);
        const params = {state, district,coordinator:e.target.value}
        const config = { params, headers };
        await axios
          .get(url, config)
          .then((res) => {
            setUsersList(res.data.data)
  
         })
          .catch((err) => {});
      }
      getUsers();

    async function getrolebasedetails() {
      const url = `${process.env.REACT_APP_URL}/getrolebasedetails`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params = {
        state, district, coordinator:e.target.value
      }
      console.log("params", params)
      const config = { params, headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("supervisor role", res.data.data)
          setRoleBaseDetails({"Supervisor":res.data.data.supervisor.length == 0 ? "" : res.data.data.supervisor[0].supervisorName, "TeamLead":res.data.data.teamlead.length == 0 ? "" : res.data.data.teamlead[0].teamleadName, "Manager":  res.data.data.manager.length == 0 ? "" : res.data.data.manager[0].name })
        })
          .catch((err) => {});
    }
    getrolebasedetails();

  }


  const rowClicked = (mobile, name, age, gender, speakerID) => {
    //console.log("mobile, name, age, gender", mobile, name, age, gender);
    history.push({ pathname : "/qc-olduserfilelist",
    state: {mobile:mobile, name:name, age:age, gender:gender, speakerID:speakerID}
  })
  };



  let columns = useMemo(
    () => [
      {
        id: "_id",
        Header: "Id",
        accessor: "_id",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "speakerID",
        accessor: "speakerID",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return  <span style={{textDecoration: "underline #22A4EE",cursor: "pointer", color:"#22A4EE"}}
          onClick={() => rowClicked(row.original.mobile, row.original.name, row.original.age, row.original.gender, row.original.speakerID)}>
          {row.original.speakerID}
        </span>
        },
      },
      {
        Header: "latitude",
        accessor: "latitude",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return(<div>{Number(row.original.latitude).toFixed(2)}</div>
          )
        },
      },
      {
        Header: "longitude",
        accessor: "longitude",
        sortType: "basic",
        filter: "text",
        Cell: ({ row }) => {
          return(<div>{Number(row.original.longitude).toFixed(2)}</div>
          )
        },
      },
      {
        Header: "Name",
        accessor: "name",
        sortType: "basic",
        filter: "text",
        //sticky: "left",
      },
      // {
      //   Header: "Total Duration",
      //   accessor: "recordedHours",
      //   sortType: "basic",
      //   filter: "text",
      // },
      {
        Header: "Pending Files",
        accessor: "qcPendingCount",
        sortType: "basic",
        filter: "text",
      }
   
    ],
    []
  );


    return (
      <>
        <div className="container entitiCustomerWrap d-flex">
          <LeftNavigation />
          <ToastContainer />
          <div className="pageRight">
            <div className="userSection">
              <div className="welcomeUser">
                Quality Checker
              </div>

              <UserProfile />
            </div>

            <div>
              <div className="d-flex justify-between regionSelectWrap">
                <div className="regionselect d-flex">
                    <div className = {`inputWrap ${
                         errors.coordinator ? "error" : ""
                      }`}>
                      <select
                          {...register("coordinator")}
                          // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                          onChange={(e) => onChangeCoordinator(e)}
                        >
                          {}
                           <option value="">Please select Coordinator</option>
                          {coordinators && coordinators.map((coordinator)=>{
                            console.log("Option coordinator", coordinator.value)
                            return <option key={coordinator.value} value={coordinator.value}>{coordinator.label}</option>
                          })}
                        </select>
                        {errors.coordinator && (
                        <span className="inputErrorMsg">
                          {errors.coordinator?.message}
                        </span>
                      )}
                    </div>
                </div>
              </div>

              <div className="d-flex justify-between">
                <div className="adminDetails">
                <div>
                    <label>State:</label>
                    <span className="value">{state}</span>
                  </div>
                  <div>
                    <label>District:</label>
                    <span className="value">{district}</span>
                  </div>
                  {/* <div>
                    <label>Manager Name:</label>
                    <span className="value">{roleBaseDetails.Manager}</span>
                  </div>
                  <div>
                    <label>Team Lead Name:</label>
                    <span className="value">{roleBaseDetails.TeamLead}</span>
                  </div> */}
                  <div>
                    <label>Supervisor Name:</label>
                    <span className="value">{roleBaseDetails.Supervisor}</span>
                  </div>
                  <div>
                    <label>Co-Ordinator Name:</label>
                    <span className="value">{coordinator}</span>
                  </div>
                  
                  {/* <div>
                    <label>Total Audio Recorded:</label>
                    <span className="value">{totalDuration}</span>
                  </div> */}
                </div>
              </div>
            </div>

            <div className="projectCoordinatorContent">
              <div className="projectContent mobileDashboard">
              <Table
                      columns={columns}
                      data={users}
                      count={users.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="users"
                    />
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default QcOldUserList;