import React , { useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import reqHeader from "../../helper/reqHeader.js";
import axios from "axios";
import Table from "../Table/Table";
import Swal from "sweetalert2";
import { CustomDialog, useDialog } from 'react-st-modal';

const schema = yup.object().shape({
  state: yup
  .string()
  .required("State is required!"),
  district: yup
  .string()
  .required("district is required!"),
   
});


const PopupEditTeamlead = (props) => {
  const id = props.teamleadID;
  console.log("id", id)
  const {
    register,
    handleSubmit,
    control,
    trigger,
    formState: { errors },
    setValue,
    getValues
  } = useForm({
    mode: "onSubmit",
    // reValidateMode: "onChange",
    resolver: yupResolver(schema)
  });

  const dialog = useDialog();

  const [states, setStates] = useState([]);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [teamleadDistrict, setTeamleadDistrict] = useState([]);


  useEffect(() => {
  
    async function getstates() {
      const url = `${process.env.REACT_APP_URL}/getstates`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const config = { headers };
      await axios
        .get(url, config)
        .then((res) => {
          //console.log("States", res.data.data);
          setStates(
            res.data.data.map(( state ) => ({ label: state, value: state }))
          );
          //console.log("From useEffect languages");
        })
        .catch((err) => {});
    }
    getstates();
  }, []);
  
  useEffect(() => {
      console.log("Inside state change", state);
      async function getDistricts() {
      let distarray = [];
      const url = `${process.env.REACT_APP_URL}/getdistrictsforteamlead`;
      const headers = reqHeader(true, true, true);
      console.log("header", headers)
      const params={
        state:state
      };
      const config = { headers, params };
      await axios
        .get(url, config)
        .then((res) => {
          console.log("Districts", res.data.data)
          // setDistricts(
          //   res.data.data.map((district) => ({ label: district.district, value: district.district }))
          // );
          setDistricts(
            res.data.data.map((data) => {return({fl:data.isTeamleadAssigned, dst:data.district})})
          )
        })
        .catch((err) => {});
    }
    getDistricts();
  }, [state]);

  useEffect(() => {
  
    async function getTeamleadDistrictList() {
      const url = `${process.env.REACT_APP_URL}/getteamleaddistrictlist`;
      const headers = reqHeader(true, true, true);
      //console.log("header", headers)
      const params = { id: id }
      const config = { params, headers };
      console.log("config", config)
      await axios
        .get(url, config)
        .then((res) => {
          setTeamleadDistrict(res.data.data);
        })
        .catch((err) => {});
    }
    getTeamleadDistrictList();
  }, []);


  let columns = useMemo(
    () => [
      {
        Header: "State",
        accessor: "state",
        sortType: "basic",
        filter: "text",
      },
      {
        Header: "District",
        accessor: "district",
        sortType: "basic",
        filter: "text",
      }
    ],
    []
  );


  const onChangeState = (e) => {
    // console.log("state", e.target.value)
    setValue('state', e.target.value,  { shouldValidate: true })
    setState(e.target.value);
  }
  
  
  const OnChangeDistrict = (e) => {
    // console.log("district", e.target.value)
    setValue('district', e.target.value,  { shouldValidate: true })
    setDistrict(e.target.value);
  }


  const submitHandler = async(data) => 
  {
         console.log("data", data);
        let teamleadDistList = [];
        teamleadDistrict.map((dstlts)=>{
          let body;
          body = {
            "state": dstlts.state,
            "district": dstlts.district
          }

          teamleadDistList = [...teamleadDistList, body]
         })

         let newObj = {
          "state": data.state,
          "district": data.district
         }
         teamleadDistList = [...teamleadDistList, newObj]

         console.log("teamleadDistList", teamleadDistList)
        

         const url = `${process.env.REACT_APP_URL}/updateteamleaddistrictlist`;
         console.log("url..",url);
         const headers = reqHeader(true, true, true);
  
         const config = {
             headers,
          };
 
         const body = {
          _id: id,
          teamLeadDistricts:teamleadDistList,
          state: data.state,
          district: data.district
         };

         try {
           const response = await axios.put(url, body, config).then((res) => {
            if (res.data.success === true && res.data.msg) {
              Swal.fire({
                icon: "success",
                title: "Teamlead Updated !!!",
                text: `District assigned Successfully !!!`,
              });
              setTimeout(()=>{
                window.location.reload();
              },2000)
            } else {
              Swal.fire({
                icon: "error",
                title: "Error !!!",
                text: "Something went wrong. Please try again",
              }).then(function () {
                setTeamleadDistrict(teamleadDistList)
                //dialog.close(res.data.success);
              
              });
            } 
          })
          .catch((err) => {
            const { error } = err.response.data;

            if ( error !== undefined) {
              Swal.fire({
                icon: "error",
                title: "Error !!!",
                text: `${error.message && error ? error : err.message}`,
              });
            }
          });

        
          //  const result = await response.data;
          // //  console.log("The user created is" + JSON.stringify(result));
          //  toast.success("District Assigned Successfully !!")
          //  setTimeout(()=>{
          //    history.push("/")
          //  }, 3000)
          
          //window.location.reload();

         } catch (error) {
           //console.log(error.response.data.error);
           //toast.error(error.response.data.error);
           //toast.error("Incorrect Details");
         }

     };

  return (
      <div className="addNewUserPop" style={{margin:"10px"}}>
          <ToastContainer />
        <br/>
        <form className="form" onSubmit={handleSubmit(submitHandler)}>
          <div className="popupForm">
            <div className="formRow child-2">
                <div className="inputBlock">
                  <label>State<span className="requiredField">*</span></label>
                    <div className = {`inputWrap ${
                              errors.state ? "error" : ""
                          }`}>
                          <select
                              {...register("state")}
                              // onChange={(e) => setValue('state', e.target.value,  { shouldValidate: true })} // Using setValue
                           
                              onChange={(e) => onChangeState(e)}
                            >
                              {}
                              <option value="">Please select State</option>
                              {states && states.map((state)=>{
                                return <option key={state.value} value={state.value}>{state.label}</option>
                              })}
                            </select>
                            {errors.state && (
                            <span className="inputErrorMsg">
                              {errors.state?.message}
                            </span>
                          )}
                    </div>
                  </div>

                  <div className="inputBlock">
                    <label>District<span className="requiredField">*</span></label>
                    <div className={`inputWrap ${errors.district ? "error" : ""}`}>
                          <select className="error"
                              {...register("district")}
                              // onChange={(e) => setValue('district', e.target.value,  { shouldValidate: true })} // Using setValue
                              onChange={(e) => OnChangeDistrict(e)}
                            >
                              <option value="">Please select District</option>
                              {districts && districts.map((district)=>{
                                // console.log("district", district)
                                return <option key={district.dst} value={district.dst}>{district.dst}</option>
                               
                              })}
                            </select>
                            {errors.district && (
                            <span className="inputErrorMsg">
                              {errors.district?.message}
                            </span>
                          )}
                        </div>
                  </div>
                </div>
          </div>

          <div className="footerAction d-flex justify-center" style={{margin:"20px"}}>
            <button className="adminUserBtn btn">Assign</button>
          </div>
        </form>
        <div className="projectCoordinatorContent">
              <div className="projectContent">
                    <Table
                      columns={columns}
                      data={teamleadDistrict}
                      count={teamleadDistrict.length}
                      pagination={true}
                      expanded={false}
                      isHeader={true}
                      filter={true}
                      isCountDisplayed={true}
                      customText="Teamlead-Districts"
                    />
              </div>
            </div>
      </div>
    );
};

export default PopupEditTeamlead;
