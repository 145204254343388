import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDialog } from "react-st-modal";
import { Redirect,useHistory} from "react-router-dom";
import axios from "axios";
import reqHeader from "../../helper/reqHeader.js"
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  reason: yup
    .string()
    //.matches(/^([^0-9]*)$/, "First Name should not contain numbers!")
    .required("Please enter the Reason for Rejection!"),
  
});

const SupervisorRejectFile = (props) => {
  const { fileId, duration, userId} = props;

  const { register, handleSubmit, errors, reset, } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  // use this hook to control the dialog
  const dialog = useDialog();
  const history=useHistory();

  const submitHandler = async(data) => {
    //debugger;
    // e.preventDefault();
    // e.persist()
  
    console.log("Inside Submit Handler data ", data);
    const headers = reqHeader(true, true, true);
        
        let body={
          id:fileId,
          reason:data.reason.replace(/\s/g, " "),
          role: "Supervisor",
          fileDuration: duration,
          userID: userId
        }
        console.log("body", body)
        const config = { headers };
        let url = `${process.env.REACT_APP_URL}/rejectfile`;
  
        await axios
          .put(url,body,config)
          .then((res) => {
            setTimeout(()=>{
              toast.success(res.data.msg);
            },3000)

            dialog.close();
            window.location.reload();
          });
    
  };

  
  return (
    <div className="SupportFilePopup">
      <ToastContainer />
         <form className="form" onSubmit={handleSubmit(submitHandler)}>
            <div className="addProjectDetails">
              <div className= {`inputWrap emailField rejectFormWrap ${errors.reason ? "error" : ""}`} style ={{margin:"10px"}}>
                <label className="label">
                  Reason for Rejection
                </label>
                <textarea
                  type="textarea"
                  name="reason"
                  ref={register}
                  rows={30}
                />
                {errors.reason && (
                    <span className="inputErrorMsg">
                      {errors.reason?.message}
                    </span>
                  )}
              </div>
              <div className="popupFooter d-flex justify-end" style={{margin:"10px"}}>
                <button className="orangeBtn btn" >Submit</button>
              </div>
            </div>
      </form>
    </div>
  );
};

export default SupervisorRejectFile;
