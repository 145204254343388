import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { SignOut } from "./icons.component";
import { logout } from "../actions/userActions";

const UserProfile = () => {

  const userLogin = useSelector((state) => state.userLogin);
  const dispatch = useDispatch();
  
  const Logout = () => {
    dispatch(logout());
  }

    return (
      <>
        <div className="userInfoWrap">
          <div className="userAvatar"></div>
          <div className="userName">
            <span className="userTxt">{userLogin.user.name}</span> <div className="twoWayArrow"></div>
          </div>

          <ul className="profileOptions">
            <li className="signOutItem" onClick={Logout}>
              <SignOut />
              <span>Sign Out</span>
            </li>
          </ul>
        </div>
      </>
    );
};

export default UserProfile;